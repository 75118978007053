import { useEffect } from "react";
import { isTokenExpired } from "../utils/auth";

const useTokenValidation = (token: string | null, onTokenExpired: () => void) => {
  useEffect(() => {
    if (token) {
      if (isTokenExpired(token)) {
        console.log("Token has expired");
        onTokenExpired();
      } else {
        console.log("Token is valid");
      }
    }
  }, [token, onTokenExpired]);
};

export default useTokenValidation;
