import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  Chip,
  Modal,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  AlertColor,
  Snackbar,
  Alert,
  FormControl,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Option, Pregunta } from "../../../interfaces/IExam";
import { updateOption, updateQuestion } from "../../../services/examServices";
import LoadingModal from "../../Loading/LoadingModal";

interface QuestionAccordionProps {
  question: Pregunta;
  callback: () => void;
  truncateLength?: number;

}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxWidth: "1000px",
  height: "auto",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

const QuestionAccordion: React.FC<QuestionAccordionProps> = ({
  question,
  truncateLength = 50,
  callback
}) => {
  const [expanded, setExpanded] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    severity: AlertColor;
    message: string;
  }>({
    open: false,
    severity: "info",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [editedOptions, setEditedOptions] = useState<Option[]>([...question.opciones]);
  const [selectedCorrectOption, setSelectedCorrectOption] = useState<string | null>(
    question.opciones.find((o) => o.esCorrecta)?.id || null
  );
  const handleOptionChange = (index: number, value: string) => {
    setEditedOptions((prev) => {
      const updated = [...prev];
      updated[index].texto = value;
      return updated;
    });
  };
  const handleCorrectOptionChange = (id: string) => {
    setSelectedCorrectOption(id);
    setEditedOptions((prev) =>
      prev.map((option) => ({
        ...option,
        esCorrecta: option.id === id,
      }))
    );
  };
  const handleSaveOptions = async () => {
    try {
      setLoading(true);
      for (const option of editedOptions) {
        await updateOption(option.id, { texto: option.texto, esCorrecta: option.esCorrecta });
      }
      setSnackbar({
        open: true,
        message: "Opciones actualizadas con éxito.",
        severity: "success",
      });
      callback();
    } catch (error) {
      console.error("Error updating options:", error);
      setSnackbar({
        open: true,
        message: "Error al actualizar las opciones.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setIsOptionsModalOpen(false);
    }
  };


  const [editedQuestion, setEditedQuestion] = useState({
    id: question.id,
    texto: question.texto,
    tipo: "libre",
    puntos: 1,
    activo: question.activo,
  });

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedQuestion((prev) => ({
      ...prev,
      [name]: name === "puntos" ? parseInt(value, 10) : value,
    }));
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedQuestion((prev) => ({
      ...prev,
      activo: e.target.checked,
    }));
  };
  const handleSave = async () => {
    setLoading(true);
    const updatedData = {
      activo: editedQuestion.activo,
      puntos: editedQuestion.puntos,
      texto: editedQuestion.texto,
    };
    try {
      await updateQuestion(editedQuestion.id, updatedData);
      setSnackbar({
        open: true,
        message: "Examen actualizado con éxito.",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating exam:", error);
      setSnackbar({
        open: true,
        message: "Error al actualizar el examen.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setIsModalOpen(false)
      callback();
    }
  };

  const truncateText = (text: string, length: number): string =>
    text.length > length ? `${text.slice(0, length)}...` : text;
  if (loading) {
    return <LoadingModal loading={loading} />;
  }
  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {expanded
              ? question.texto
              : truncateText(question.texto, truncateLength)}
          </Typography>
        </AccordionSummary>
        
        <AccordionActions>
          <Button
            variant="contained"
            sx={{ mb: 1, mr: 1 }}
            onClick={() => {setIsModalOpen(true)
              setExpanded(false);

            }}
          >
            Editar pregunta
          </Button>
          <Button variant="outlined" color={"secondary"} sx={{ mb: 1, mr: 1 }}
          onClick={() => {
            setIsOptionsModalOpen(true);
            setExpanded(false);
          }}>
            Editar respuestas
          </Button>
        </AccordionActions>
      </Accordion>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6" mb={2}>
            Editar Pregunta
          </Typography>
          <TextField
            fullWidth
            label="Texto de la pregunta"
            name="texto"
            value={editedQuestion.texto}
            onChange={handleEditChange}
            margin="normal"
            multiline
            maxRows={5}
          />
          <TextField
            fullWidth
            label="Puntos"
            name="puntos"
            type="number"
            value={editedQuestion.puntos}
            onChange={handleEditChange}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editedQuestion.activo}
                onChange={handleCheckboxChange}
              />
            }
            label="Activo"
          />
          <Box mt={2} textAlign="right">
            <Button
              variant="outlined"
              onClick={() => setIsModalOpen(false)}
              sx={{ mr: 1 }}
            >
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Options Modal */}
      <Modal
        open={isOptionsModalOpen}
        onClose={() => setIsOptionsModalOpen(false)}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" mb={2}>
            Editar Respuestas
          </Typography>
          <Box  display="flex" alignItems="center" mb={2} sx={{ width: '100%'}}>
            <Box sx={{ flex: 0.85 }}><Typography>{question.texto}</Typography></Box>
            <Box sx={{ flex: 0.15, textAlign: 'center' }}><Typography fontWeight={'bold'} color="success">{"Respuesta correcta"}</Typography></Box>

          </Box>
            {editedOptions.map((option, index) => (
            <Box key={option.id} display="flex" alignItems="center" mb={2} sx={{ width: '100%'}}>
            <TextField
              fullWidth
              label={`Opción ${index + 1}`}
              value={option.texto}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              margin="normal"
              sx={{ flex: 0.85 }} 
            />
            <Box sx={{ flex: 0.15, display: 'flex', justifyContent: 'center' }}>
            <FormControl>

              <Radio
                checked={selectedCorrectOption === option.id}
                onChange={() => handleCorrectOptionChange(option.id)}
                value={option.id}
                name="correctOption"
                inputProps={{ "aria-label": `Opción ${index + 1}` }}
              />          </FormControl>

            </Box>
          </Box>
          
            ))}
          <Box mt={2} textAlign="right">
            <Button
              variant="outlined"
              onClick={() => setIsOptionsModalOpen(false)}
              sx={{ mr: 1 }}
            >
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSaveOptions}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>
            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
            >
              <Alert
                onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
                severity={snackbar.severity}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
            
    </>
  );
};

export default QuestionAccordion;
