import React, { useEffect, useState } from "react";
import {
  TablePagination,
  Paper,
  Box,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePaper,
  TableRow,
} from "../Table/CustomeTable";
import { EstudiantePractico, Examen } from "../../interfaces/IExam";


interface ParticipantsTableSinodalProps {
  initialEstudiantes: EstudiantePractico[];
  searchTerm: string;
  callBackAction: (sustentante: EstudiantePractico) => void;
}
const ParticipantsTableSinodal: React.FC<ParticipantsTableSinodalProps> = React.memo(
  ({ initialEstudiantes, searchTerm, callBackAction }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [estudiantes, setEstudiantes] =useState<EstudiantePractico[]>(initialEstudiantes);
    

    useEffect(() => {
      setEstudiantes(initialEstudiantes);
    }, [initialEstudiantes]);

 
    const filteredEstudiantes = estudiantes?.filter((estudiante) => {
      const nombre = (estudiante.nombre+" "+estudiante.apellidoPaterno+" "+estudiante.apellidoMaterno).toLowerCase();
      const email = estudiante.email.toLowerCase();
      const searchLower = searchTerm?.toLowerCase() || "";
      return nombre.includes(searchLower) || email.includes(searchLower);
    });

    const paginatedEstudiantes = filteredEstudiantes.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    return (
      <>
        <TablePaper>
          <Table>
            <TableHead color="#111">
              <TableRow>

                <TableCell colSpan={2} >Nombre del sustentante</TableCell>
                <TableCell align="center">Cédula Profesional</TableCell>
                <TableCell align="center">Correo electrónico</TableCell>
                <TableCell align="center">Estación</TableCell>

                <TableCell colSpan={2} align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedEstudiantes
                .slice()
                .sort((a, b) =>
                  a.nombre.localeCompare(b.nombre, "es", {
                    sensitivity: "base",
                  })
                )
                .map((estudiante) => (
                <TableRow key={estudiante.examenUsuarioId}>
                  <TableCell colSpan={2} >
                      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{estudiante.nombre+" "+estudiante.apellidoPaterno+" "+estudiante.apellidoMaterno}</div>
                  </TableCell>
                 
                  <TableCell align="center">{estudiante.cedulaProfesional}</TableCell>
                  <TableCell align="center">{estudiante.email}</TableCell>
                  <TableCell align="center">{estudiante.intentos}</TableCell>
                
                  <TableCell colSpan={2} align="center">
                    <Stack direction="row" spacing={1} justifyContent="center">
                    <Button
                    size="small"
                          onClick={() =>{callBackAction(estudiante)}
                          }
                          variant="contained"
                          color="secondary"
                        >
                          Aplicar Examen
                        </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredEstudiantes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
          />
        </TablePaper>
       
      </>
    );
  }
);

export default ParticipantsTableSinodal;
