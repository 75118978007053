import React, { useState, useEffect  } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Grid,
  Box
} from "@mui/material";
import { EstudianteExamen } from "../../interfaces/IMonitoreo";
import { getIndividualReport } from "../../services/reportServices";

interface ReportStudentDialogProps {
  openModal: boolean;
  handleCloseModal: () => void;
  students: EstudianteExamen[];
}

const ReportStudentDialog: React.FC<ReportStudentDialogProps> = ({
  openModal,
  handleCloseModal,
  students,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedStudent, setSelectedStudent] = useState<EstudianteExamen | null>(null);

  useEffect(() => {
    if (openModal) {
      setSearchTerm(""); // Limpia el texto de búsqueda
      setSelectedStudent(null); // Limpia la selección previa
    }
  }, [openModal]);

  // Filter students based on the search term
  const filteredStudents = students.filter((student: EstudianteExamen) =>
    student.estudiante.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleStudentDownload = async () => {
    if (!selectedStudent) return;

    try {
      const response = await getIndividualReport(selectedStudent.examenUsuarioId);
      const blob = new Blob([response], { type: "application/xlsx" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `reporte_individual_sustentante_${selectedStudent.estudiante.nombre}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading student report:", error);
    }
  };
  return (
<Dialog
  open={openModal}
  onClose={handleCloseModal}
  PaperProps={{
    sx: {
      height: "80vh",
      width: "50vw",
      maxHeight: "100vh",
      maxWidth: "100vw",
    },
  }}
>
  <DialogTitle sx={{ fontWeight: "bold", color: "#333" }}>
    Selecciona al sustentante para generar el reporte
  </DialogTitle>

  <DialogContent dividers>
    <Box
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 2,
        paddingBottom: 2,
      }}
    >
      <TextField
        fullWidth
        label="Buscar sustentante por nombre"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
        InputProps={{
          sx: { backgroundColor: "#f9f9f9", borderRadius: 1 },
        }}
      />
    </Box>

    {filteredStudents.length > 0 ? (
      filteredStudents.map((student: EstudianteExamen) => (
        <Grid
          key={student.examenUsuarioId}
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: 1,
            borderBottom: "1px solid #ddd",
            "&:last-child": { borderBottom: "none" },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedStudent === student}
                onChange={() => setSelectedStudent(student)}
                sx={{ color: "#1976d2" }}
              />
            }
            label={
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {student.estudiante.nombre}
              </Typography>
            }
          />
        </Grid>
      ))
    ) : (
      <Typography
        sx={{
          textAlign: "center",
          marginTop: 3,
          fontStyle: "italic",
          color: "#999",
        }}
      >
        No se encontraron sustentantes con ese nombre.
      </Typography>
    )}
  </DialogContent>

  <DialogActions>
    <Button onClick={handleCloseModal} color="secondary" variant="outlined">
      Cancelar
    </Button>
    <Button
      onClick={handleStudentDownload}
      color="primary"
      variant="contained"
      disabled={!selectedStudent}
    >
      Descargar
    </Button>
  </DialogActions>
</Dialog>
  );
};

export default ReportStudentDialog;
