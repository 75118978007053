import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  Alert,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import background from "../assets/img/background-forgot.png";
import logo from "../assets/img/logo.png";
import { useSnackbar } from "../components/Snackbar/Snackbar";
import SnackbarAlert from "../components/Snackbar/SnackbarAlert";
import { requestChangePassword } from "../services/authServices";
import LoadingModal from "../components/Loading/LoadingModal";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestChange = async () => {
    setLoading(true);
    try {
      const response = await requestChangePassword(email);
      if (response && response.message) {
        showSnackbar(response.message, "success");
        setTimeout(() => navigate("/"), 2000);
      }
      setLoading(false);
    } catch (err) {
      showSnackbar(
        `Hubo un problema al enviar el correo. Inténtelo de nuevo.`,
        "error"
      );
      setLoading(false);
    }
  };
  return (
    <Box display="flex" height="100vh">
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#f5f7fa", padding: 4, paddingTop: "10%" }}
      >
        <img
          src={background}
          alt="Background"
          style={{ height: "380px", marginBottom: "180px" }}
        />
      </Box>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#f5f7fa",
          width: { xs: "100%", sm: "50%", md: "40%" },
        }}
      >
        <Paper
          elevation={1}
          sx={{
            paddingTop: "5%",
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 2,
            width: "100%",
            maxWidth: 350,
            minHeight: "90vh",
            mx: 2,
            textAlign: "center",
          }}
        >
          <img src={logo} width={100} height={100} alt="ExamSys logo" />
          <Typography variant="h5" gutterBottom>
            Recuperar contraseña
          </Typography>
          {/* {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )} */}
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            fullWidth
            variant="contained"
            onClick={handleRequestChange}
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "primary",
              color: "white",
              "&:hover": { backgroundColor: "secondary" },
            }}
          >
            Enviar correo
          </Button>
          <Typography variant="body2" color="textSecondary">
            Regresar a{" "}
            <Link href="/" variant="body2" underline="hover">
              Iniciar sesión
            </Link>
          </Typography>
        </Paper>
      </Box>
      <LoadingModal loading={loading} />

      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default ForgotPassword;
