import * as React from "react";
import {
  CardContent,
  Typography,
  Grid2 as Grid,
  TablePagination,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../Table/CustomeTable";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../Loading/LoadingModal";
import { useFetchData } from "../../hooks/services";
import AddIcon from "@mui/icons-material/Add";
import { getShopList } from "../../services/ventasService";
import { Shop } from "../../interfaces/IExam";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const ControlVentas: React.FC = () => {
  const { data: ventas, loading } = useFetchData(() => getShopList(), []);

  const [filteredUsers, setFilteredUsers] = React.useState<any[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate(); 

  React.useEffect(() => {
    if (ventas) {
      const filtered = ventas.filter((shopList: Shop) => {
        const fullName = `${shopList.examen.nombre}`.toLowerCase();
        const searchLower = searchText.toLowerCase();
  
        return fullName.includes(searchLower) ;

      });
      setFilteredUsers(filtered);
    }
  }, [ventas, searchText]);

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const handleEditClick = (item: Shop) => {
    navigate(`/control/ventas/${item.id}`, { state: { item, edit: true } });
  };
  const handleAddClick = () => {
    navigate('/control/ventas/crear');
  };
  
  if (loading) {
    return <LoadingModal loading={loading} />;
  }

  if (!ventas || ventas.length === 0) {
    return <Typography>No se encontraron exámenes registrados</Typography>;
  }

  return (
    <CardContent>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>


        <Grid sx={{ width: '100%', display:'flex'}}> 
          <Box sx={{ width: '60%'}}>
          <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
            Catálogo de exámenes en venta
            
          </Typography>
 
          </Box>
          <Button sx={{margin: 'auto'}} variant="contained" onClick={() => handleAddClick()} startIcon={<AddIcon />}>
          Crear
        </Button>
           <FormControl sx={{ m: 1 , width:'35%'}}>
          <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            placeholder="Buscar por Nombre"
            startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
            label="Amount"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
          />
        </FormControl>
        
        </Grid>
      </Grid>

      <TablePaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre del exámen</TableCell>      
              <TableCell>Precio de venta</TableCell>
              <TableCell>Fecha de aplicación del exámen</TableCell>
              <TableCell>Duración del exámen</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((shopList: Shop) => (
                <TableRow key={shopList.id}>
                  <TableCell>{shopList.examen.nombre}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('es-MX', { 
                    style: 'currency', 
                    currency: 'MXN', 
                    minimumFractionDigits: 0, 
                    maximumFractionDigits: 0 
                  }).format(shopList.precioVenta)}
                  </TableCell>
                  <TableCell>
                      {shopList.examen.fechaAplicacion
                      ? shopList.examen.fechaAplicacion.split("T")[0].split("-").reverse().join("/")
                      : "Fecha no disponible"}
                  </TableCell>
                  <TableCell>{shopList.examen.tiempoLimite} minutos</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditClick(shopList)}
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TablePaper>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 15]}
          labelRowsPerPage="Filas por página"
        />
      </Box>
    </CardContent>
  );
};

export default ControlVentas;
