import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  AlertColor,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Examen } from "../../../interfaces/IExam";
import { updateExam } from "../../../services/examServices";




const formatDate = (dateString: string | undefined) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Formato 'YYYY-MM-DD'
};



interface ExamenFormProps {
  examen: Examen;
}

const ExamenForm: React.FC<ExamenFormProps> = ({ examen }) => {
  const [formData, setFormData] = useState({
    nombre: examen.nombre,
    descripcion: examen.descripcion,
    tiempoLimite: examen.tiempoLimite,
    activo: examen.activo,
    tipoExamen: examen.tipoExamen,
    fechaAplicacion: examen.fechaAplicacion,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    severity: AlertColor;
    message: string;
  }>({
    open: false,
    severity: "info",
    message: "",
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      activo: examen.activo,
    }));
  }, [examen]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      activo: e.target.checked,
    }));
  };

  const handleSave = async () => {
    setIsEditing(false);
    const updatedData = {
      ...formData,
      tiempoLimite: Number(formData.tiempoLimite),
    };
    try {
      await updateExam(examen.id, updatedData);
      setSnackbar({
        open: true,
        message: "Examen actualizado con éxito.",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating exam:", error);
      setSnackbar({
        open: true,
        message: "Error al actualizar el examen.",
        severity: "error",
      });
    }
  };

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const tiposExamen: Record<string, string> = {
    teorico: "Teórico",
    practico: "Práctico",
  };

  return (
    <Box width="100%" margin="auto">

      <Card
        sx={{
          marginBottom: 3,
          boxShadow: 3,
          padding: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={3}
          >
            <Typography
              sx={{ fontWeight: "bold", color: "#1e293b", fontSize: 18 }}
            >
              Información del examen
            </Typography>
            <Box>
              {isEditing && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setFormData({
                      nombre: examen.nombre,
                      descripcion: examen.descripcion,
                      tiempoLimite: examen.tiempoLimite,
                      activo: examen.activo,
                      tipoExamen:examen.tipoExamen,
                      fechaAplicacion:examen.fechaAplicacion,
                    });
                    setIsEditing(false);
                  }}
                  sx={{ marginRight: 1 }}
                >
                  Cancelar
                </Button>
              )}
              <Button
                variant="contained"
                color={isEditing? "primary": "secondary"}
                onClick={isEditing ? handleSave : toggleEdit}
              >
                {isEditing ? "Guardar" : "Editar"}
              </Button>
            </Box>
          </Box>

          <Box display="grid" gap={2} gridTemplateColumns="1fr 3fr">
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", alignSelf: "center" }}
            >
              Nombre:
            </Typography>
            {isEditing ? (
              <TextField
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                size="small"
                fullWidth
                variant="outlined"
                sx={{ backgroundColor: "white" }}
              />
            ) : (
              <Typography>{formData.nombre}</Typography>
            )}

            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", alignSelf: "center" }}
            >
              Descripción:
            </Typography>
            {isEditing ? (
              <TextField
                name="descripcion"
                value={formData.descripcion}
                onChange={handleInputChange}
                size="small"
                fullWidth
                variant="outlined"
                sx={{ backgroundColor: "white" }}
              />
            ) : (
              <Typography>{formData.descripcion}</Typography>
            )}
            
            <Typography variant="body1" sx={{ fontWeight: "bold", alignSelf: "center" }}>
              Tipo de examen:
            </Typography>
            {isEditing ? (
              <Select
              name="tipoExamen"
              value={formData.tipoExamen}
              onChange={handleInputChange}
              size="small"
              fullWidth
              variant="outlined"
              sx={{ backgroundColor: "white" }}
            >
              <MenuItem value="teorico">Teórico</MenuItem>
              <MenuItem value="practico">Práctico</MenuItem>
            </Select>
            ) : (
              <Typography>{tiposExamen[formData.tipoExamen] || formData.tipoExamen}</Typography>
            )}

            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", alignSelf: "center" }}
            >
              Tiempo límite (minutos):
            </Typography>
            {isEditing ? (
              <TextField
                name="tiempoLimite"
                value={formData.tiempoLimite}
                onChange={handleInputChange}
                size="small"
                fullWidth
                type="number"
                variant="outlined"
                sx={{ backgroundColor: "white" }}
              />
            ) : (
              <Typography>{formData.tiempoLimite} minutos</Typography>
            )}


            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", alignSelf: "center" }}
            >
              Fecha de aplicación:
            </Typography>
            {isEditing ? (
              <TextField
                type="date"
                name="fechaAplicacion"
                value={formatDate(formData.fechaAplicacion)}
                onChange={handleInputChange}
                size="small"
                fullWidth
                variant="outlined"
                sx={{ backgroundColor: "white" }}
              />
            ) : (
            <Typography>
              {formData.fechaAplicacion
                ? formData.fechaAplicacion.split("T")[0].split("-").reverse().join("/")
                : "Fecha no disponible"}
            </Typography>
            )}

            {isEditing && (
              <>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", alignSelf: "center" }}
                >
                  ¿Activo?
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.activo}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Activo"
                />
              </>
            )}
          </Box>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ExamenForm;
