import React from "react";
import { Modal, Box, CircularProgress, Typography } from "@mui/material";

interface LoadingModalProps {
  loading: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ loading }) => {
  return (
    <Modal
      open={loading}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.paper",
          padding: 2,
          borderRadius: 1,
          boxShadow: 24,
        }}
      >
        <CircularProgress />
        <Typography sx={{paddingLeft: 2}}>Cargando...</Typography>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
