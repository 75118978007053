import * as React from "react";
import {
  CardContent,
  Typography,
  Grid2 as Grid,
  TablePagination,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../../Table/CustomeTable";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Examen } from "../../../interfaces/IExam";
import { useAuth } from "../../../context/AuthContext";
import { useFetchData } from "../../../hooks/services";
import { getSinodalService, getSustentantesService } from "../../../services/userServices";
import { Admin, User } from "../../../interfaces/IUser";
import LoadingModal from "../../Loading/LoadingModal";
import { inscribirExamen } from "../../../services/examServices";
import SnackbarAlert from "../../Snackbar/SnackbarAlert";
import { useSnackbar } from "../../Snackbar/Snackbar";

const ExamenInscribir: React.FC = () => {
    const location = useLocation();
  const examen: Examen = location.state?.exam;
  const { data: users, loading } = useFetchData(() => getSustentantesService(), []);
  const [filteredUsers, setFilteredUsers] = React.useState<any[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate(); 
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();

  React.useEffect(() => {
    if (users) {
      const filtered = users.filter((user: User) => {
        const fullName = `${user.nombre} ${user.apellidoPaterno} ${user.apellidoMaterno}`.toLowerCase();
        const email = user.email.toLowerCase();
        const cedula = user.cedulaProfesional.toLowerCase();
        const searchLower = searchText.toLowerCase();
  
        return fullName.includes(searchLower) || email.includes(searchLower) ||  cedula.includes(searchLower);
      });
      setFilteredUsers(filtered);
    }
  }, [users, searchText]);

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const inscribieExamenUsuario = async (userId: string) => {
      if (!examen) {
        navigate("/control/exams")
        return
      };
  
      const examenId = examen.id;

      try {
        const response = await inscribirExamen(userId, examenId);
        if (response.error) {
  
          showSnackbar(response.error , "error");
        } else {
          showSnackbar(`Se inscribio al sustentante corretamente` , "success");
  
        }
      } catch (error: any) {
        console.error(error.message);
        showSnackbar("El sustentante ya se encuentra inscrito" , "error");

      } finally {
       
      }
    };
  
  if (loading) {
    return  <LoadingModal loading={loading} />;
  }

  if (!users || users.length === 0) {
    return <Typography>No se encontraron usuarios registrados</Typography>;
  }

  return (
    <>
    <CardContent>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>


        <Grid sx={{ width: '100%', display:'flex'}}> 
          <Box sx={{ width: '60%'}}>
          <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
            {"Inscribir a " +examen.nombre}
          </Typography>
          
          </Box>
           <FormControl sx={{ m: 1 , width:'35%'}}>
          <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            placeholder="Buscar por Nombre, Correo o Cedula"
            startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
            label="Amount"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
          />
        </FormControl>
        </Grid>
      </Grid>

      <TablePaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Correo</TableCell>
              <TableCell>Cedula</TableCell>
              <TableCell>Universidad</TableCell>
              <TableCell>Fecha Creación</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user: User) => (
                <TableRow key={user.id}>
                  <TableCell>{user.nombre+" "+user.apellidoPaterno+" "+user.apellidoMaterno}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.cedulaProfesional}</TableCell>
                  <TableCell>{user.universidad}</TableCell>
                  <TableCell>
                  {user.fechaCreacion
                    ? user.fechaCreacion.split("T")[0].split("-").reverse().join("/")
                    : "Fecha no disponible"}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => inscribieExamenUsuario(user.id)}
                    >
                      Inscribir
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TablePaper>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 15]}
          labelRowsPerPage="Filas por página"
        />
      </Box>
    </CardContent>
    <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default ExamenInscribir;

