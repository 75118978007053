import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  fetchSinodalModules,
  iniciarExamenSinodal,
} from "../../services/examServices";
import {
  EstudiantePractico,
  Examen,
  Pregunta,
  ResponseModule,
} from "../../interfaces/IExam";
import LoadingModal from "../Loading/LoadingModal";

import { useLocation } from "react-router-dom";
import ExamDetail from "../Dashboard/ExamDetail";
import SustentanteInfoBox from "../Cards/SustentanteInfoBox";
import { useAuth } from "../../context/AuthContext";
import { Box, List, ListItem, ListItemButton, ListSubheader } from "@mui/material";
import NewQuestionnaire from "../Questionarie/NewQuestionarie";

const SinodalExam: React.FC = () => {
  const location = useLocation();
  const { sinodal, examModeSet } = useAuth();
  const exam: Examen = location.state?.exam;
  const sustentante: EstudiantePractico = location.state?.sustentante;
  const [module, setModules] = useState<ResponseModule>();
  const [isExamStarted, setIsExamStarted] = useState<boolean>(false);
  const [endExam, setEndExam] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [clickedItemList, setClickedItemList] = useState<Pregunta>();

  useEffect(() => {
    loadModuleData();
  }, []);

  const loadModuleData = async () => {
    setLoading(true);
    try {
      if (sustentante) {
        const exams = await fetchSinodalModules(exam.id, sustentante?.id);
        setModules(exams);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Auto-inicia el examen al cargar la página
  useEffect(() => {
    if (!isExamStarted && sustentante && exam && sinodal) {
      startExam();
    }
  }, [isExamStarted, sustentante, exam, sinodal]);

  const startExam = async () => {
    try {
      if (sustentante && exam && sinodal) {
        await iniciarExamenSinodal(sustentante?.id, exam?.id, sinodal.id);
        setIsExamStarted(true);
        examModeSet(true);
      }
    } catch (error) {
      console.error("Error al iniciar el examen:", error);
    }
  };

  const updateData = (md: ResponseModule) => {
    setModules(md);
  };

  if (loading) {
    return <LoadingModal loading={loading} />;
  }

  if (!module) return <Typography>No se pudo iniciar el examen</Typography>;

  return (
    <>
      <CardContent>
        {!endExam && <SustentanteInfoBox sustentante={sustentante} />}
        <>
          {isExamStarted && module ? (
            <Box
              sx={{
                display: "flex",
                alignContent: "space-between",
                flexDirection: "row",
                width: "100%",
                height: "100vh",
              }}
            >
              {/* Main Content */}
              <Box sx={{ width: endExam ? "100%" : "70%" }}>
                <NewQuestionnaire
                  dataModule={module}
                  exam={exam}
                  updatedata={updateData}
                  clickedQuestion={clickedItemList}
                  endExam={setEndExam}
                  esPractico={{
                    sinodalId: sinodal?.id,
                    sustentante: sustentante.id,
                    intento: sustentante.intentos,
                  }}
                />
              </Box>

              {/* Sidebar */}
              {!endExam && (
                <Box
                  sx={{
                    width: "30%",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    padding: 0.5,
                    backgroundColor: "#f5f5f5",
                    borderRadius: 5,
                  }}
                >
                  <List
                    sx={{
                      height: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                      padding: 0,
                    }}
                  >
                    {module?.modulos.map((modulo: any) => (
                      <>
                        <ListSubheader
                          sx={{
                            backgroundColor: "#f5f5f5",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            borderBottom: "1px solid #ddd",
                            paddingY: 1,
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                            {modulo.nombreModulo}
                          </Typography>
                        </ListSubheader>

                        {modulo.preguntas.map((pregunta: Pregunta, index: number) => (
                          <ListItem
                            key={`pregunta-${pregunta.id}-${index}`}
                            disablePadding
                            sx={{
                              width: "auto",
                              height: 35,
                              marginBottom: index === modulo.preguntas.length - 1 ? 2 : 0,
                            }}
                          >
                            <ListItemButton onClick={() => setClickedItemList(pregunta)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  overflowX: "hidden",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "90%",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <Typography
                                    noWrap
                                    sx={{
                                      overflowX: "hidden",
                                      textOverflow: "ellipsis",
                                      fontSize: 14,
                                      width: 300,
                                    }}
                                  >
                                    {pregunta.texto}
                                  </Typography>
                                </Box>
                              </Box>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          ) : (
            module?.modulos && (
              <ExamDetail modules={module?.modulos} startExam={startExam} exam={exam} />
            )
          )}
        </>
      </CardContent>
    </>
  );
};

export default SinodalExam;
