import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ParticipantsTable from "./ParticipantsTable";
import ExamInfoBox from "../../Cards/ExamInfoBox";
import { useLocation, useParams } from "react-router-dom";
import { getExamStudents } from "../../../services/monitoreoServices";
import { ExamenMonitoreo } from "../../../interfaces/IMonitoreo";
import LoadingModal from "../../Loading/LoadingModal";

const MonitoreoDetail = () => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const examName = location.state?.examName;
  const [examenes, setExamenes] = useState<ExamenMonitoreo | null>(null);

  const fetchStatus = async () => {
    try {
      setLoading(true);
      const response = await getExamStudents(id + "");
      if (!response) {
        throw new Error("Failed to fetch data");
      }

      setExamenes(response);
    } catch (error) {
      console.error("Error fetching status:", error);
    } finally {
      setLoading(false);
      setTimeout(fetchStatus, 3000); // Long polling
    }
  };

  useEffect(() => {
    fetchStatus(); // Start polling on mount
    return () => {
      console.log("Component unmounted. Polling stopped.");
    };
  }, []);

  return (
    <Box sx={{ mb: 5 }}>
      <ExamInfoBox onSearchChange={setSearchTerm} examName={examName} />
      <Box sx={{ maxHeight: "60vh" }}>
        {examenes ? (
          <ParticipantsTable
            initialEstudiantes={examenes.estudiantes}
            searchTerm={searchTerm}
          />
        ) : (
          <LoadingModal loading={loading} />
        )}
      </Box>
    </Box>
  );
};

export default MonitoreoDetail;
