import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Box,
  Paper,
  Input,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getAdminExams, uploadExam } from "../../services/examServices";
import { Examen } from "../../interfaces/IExam";
import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../Table/CustomeTable";

import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import LoadingModal from "../Loading/LoadingModal";
import { useFetchData } from "../../hooks/services";
import { useAuth} from "../../context/AuthContext";

const ControlExams: React.FC = () => {
  const { adminToken} = useAuth();
  const { data: examenes, loading, error } = useFetchData(() => getAdminExams(), []);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const tiposExamen: Record<string, string> = {
    teorico: "Teórico",
    practico: "Práctico",
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file before uploading.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      await uploadExam(formData, adminToken ?? "");
      
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return (
      <LoadingModal loading={loading} />

    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        Ocurrió un error al cargar los exámenes.
      </Typography>
    );
  }

  

  return (
    <CardContent>
      <Grid container  sx={{ marginBottom: 5}} alignItems="center">
  <Box sx={{width: '80%' }}>
    <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
      Exámenes
    </Typography>
  </Box>
  <Box sx={{width: '20%' }}>
    <Button
      onClick={() => {
        navigate(`/control/cargaExamen`)

      }}
      variant="contained"
      color="secondary">
      Subir examen
    </Button>
   
  </Box>
</Grid>

      <TablePaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Nombre</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Tipo de examen</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Fecha aplicación</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Tiempo limite</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Estatus</strong></TableCell>
              <TableCell colSpan={2} align="center"><strong>Acciones</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examenes?.map((examen: Examen) => (
              <TableRow key={examen.id}>
                <TableCell sx={{ verticalAlign: "middle" }}>{examen.nombre}</TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>{tiposExamen[examen.tipoExamen] || examen.tipoExamen}</TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  {examen.fechaAplicacion
                    ? examen.fechaAplicacion.split("T")[0].split("-").reverse().join("/")
                    : "Fecha no disponible"}

                </TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>{examen.tiempoLimite } min</TableCell>
                
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>{examen.activo? "Activo": "Inactivo"} </TableCell>
                <TableCell colSpan={2}>
                <Stack direction="row" spacing={1} justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/control/exams/inscribir/${examen.id}`, { state: { exam: examen } })
                    }
                  >
                    Inscribir
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      navigate(`/control/exams/${examen.id}`, { state: { exam: examen } })
                    }
                  >
                    Ver
                  </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TablePaper>
    </CardContent>
  );
};

export default ControlExams;
