import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { registerService } from "../services/authServices";
import { IRegister } from "../interfaces/IUser";
import SnackbarAlert from "../components/Snackbar/SnackbarAlert";
import { useSnackbar } from "../components/Snackbar/Snackbar";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingModal from "../components/Loading/LoadingModal";
import { estadosMexico } from "../Constants/constants";

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const togglePasswordVisibility = (field: "password" | "confirmPassword") => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };
  const [formData, setFormData] = useState<IRegister>({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    celular: "",
    cedulaProfesional: "",
    universidad: "",
    email: "",
    estadoOrigen: "",
    password: "",
    confirmPassword: "",
  });

  // const [error, setError] = useState<string | null>(null);
  // const [success, setSuccess] = useState<string | null>(null);
  // const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const validateForm = (): boolean => {
    const {
      nombre,
      apellidoPaterno,
      apellidoMaterno,
      celular,
      cedulaProfesional,
      universidad,
      email,
      estadoOrigen,
      password,
      confirmPassword,
    } = formData;

    if (
      !nombre ||
      !apellidoPaterno ||
      !apellidoMaterno ||
      !celular ||
      !cedulaProfesional ||
      !universidad ||
      !email ||
      !estadoOrigen ||
      !password ||
      !confirmPassword
    ) {
      showSnackbar(`Por favor, complete todos los campos`, "error");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      showSnackbar(`Por favor, ingrese un correo electrónico válido`, "error");
      return false;
    }

    const celularPattern = /^[0-9]*$/;
    if (!celularPattern.test(celular)) {
      showSnackbar(
        `El número de celular no debe de tener letras o caracteres`,
        "error"
      );
      return false;
    }

    if (password !== confirmPassword) {
      showSnackbar(`Las contraseñas no coinciden`, "error");
      return false;
    }
    return true;
  };

  const handleRegister = async () => {
    if (!validateForm()) return;
    setLoading(true);
    try {
      const response = await registerService(formData);
      if (
        response &&
        response.message === "Usuario creado e inscrito exitosamente."
      ) {
        showSnackbar(
          `Registro exitoso. Ahora puede iniciar sesión.`,
          "success"
        );
        setTimeout(() => navigate("/"), 2000); // Redirect to login after 2 seconds
      }
      setLoading(false);
    } catch (err) {
      showSnackbar(
        `No se pudo completar el registro. Inténtelo de nuevo.`,
        "error"
      );
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Box display="flex" height="100vh">
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#f5f7fa",
          width: { xs: "100%", sm: "50%", md: "40%" },
        }}
      >
        <Paper
          elevation={1}
          sx={{
            paddingTop: "5%",
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 2,
            pb: 5,
            width: "100%",
            maxWidth: "60%",
            minHeight: "80vh",
            maxHeight: "80vh",
            mx: 2,
            overflowY: "scroll",
            textAlign: "center",
          }}
        >
          <img src={logo} width={100} height={100} alt="ExamSys logo" />
          <Typography variant="h6" fontWeight={"bold"} gutterBottom>
            Registrate
          </Typography>

          <TextField
            fullWidth
            label="Nombre"
            margin="normal"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Apellido Paterno"
            margin="normal"
            name="apellidoPaterno"
            value={formData.apellidoPaterno}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Apellido Materno"
            margin="normal"
            name="apellidoMaterno"
            value={formData.apellidoMaterno}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Celular"
            margin="normal"
            name="celular"
            type="tel"
            value={formData.celular}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Cédula Profesional"
            margin="normal"
            name="cedulaProfesional"
            value={formData.cedulaProfesional}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Universidad"
            margin="normal"
            name="universidad"
            value={formData.universidad}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
          >
            <InputLabel id="estado-select-label">Estado de origen</InputLabel>
            <Select
              labelId="estado-select-label"
              name="estadoOrigen"
              sx={{textAlign: 'left'}}
              value={formData.estadoOrigen}
              onChange={handleChangeSelect}
              label="Estado de origen"
            >
              {estadosMexico.map((estado) => (
                <MenuItem key={estado} value={estado}>
                  {estado}
                </MenuItem>
              ))}
            </Select>
            
          </FormControl>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            type={showPassword.password ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword.password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Confirmar Password"
            margin="normal"
            type={showPassword.confirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {showPassword.confirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleRegister}
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "primary",
              color: "white",
              "&:hover": { backgroundColor: "secondary" },
            }}
          >
            Registrarse
          </Button>
          <Typography variant="body2" color="textSecondary">
            ¿Ya tienes una cuenta?{" "}
            <Link href="/" variant="body2" underline="hover">
              Iniciar sesión
            </Link>
          </Typography>
        </Paper>
      </Box>

      {/* <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{success ? "Registro Exitoso" : "Error"}</DialogTitle>
        <DialogContent>
          <Typography>{success || error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
      <LoadingModal loading={loading} />
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Register;
