import axios from "axios";
import { Shop , ShopCreate} from "../interfaces/IExam";
const serviceUrl = "https://backexams-624915669123.us-central1.run.app";

export const getShopList = async (): Promise<Shop[]> => {
    const response = await axios.get(serviceUrl+'/shop/catalog/');
    return response.data;
  };

  export const createVenta =  async (formData: ShopCreate) => {
    const url = serviceUrl+'/shop/catalog/nuevo';
    const response = await axios.post(url,formData,{
      headers: { "Content-Type": "application/json" },
    })
    return response.data;
  }
  export const updateVentas = async (idCatalogo: string, updatedData: any) => {
    const url = serviceUrl+'/shop/catalog/edit/'+idCatalogo;
    const response = await axios.put(url, updatedData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };
