import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../../interfaces/IUser";
import { updateSustentante } from "../../../services/userServices";

const SustentanteEdit: React.FC = () => {
  const { state } = useLocation();
  const user: User = state?.user;

  const [formData, setFormData] = useState<User>({
    ...user,
    activo: user.activo ?? false,
  });
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFormData((prev) => ({ ...prev, activo: checked }));
  };

  const handleSubmit = async () => {
    try {
      await updateSustentante(formData.id, formData); // Call your patch API
      setSnackbar({
        open: true,
        message: "Usuario actualizado con éxito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000); // Navigate back after 2 seconds
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
      setSnackbar({
        open: true,
        message: "Hubo un error al actualizar el usuario.",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (!user) {
    return <Typography>Error: No user data available.</Typography>;
  }

  return (
    <CardContent>

    <Typography sx={{marginBottom: 5}}  variant="h6" fontWeight="bold" component="h2" gutterBottom>
     Editar Sustentante
    </Typography>
    <Box component="form" onSubmit={handleSubmit} noValidate>
      {/* Fields Container */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2, // Consistent spacing between fields
          marginBottom: 2, // Space below the input section
        }}
      >
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}> {/* Responsive width */}
          <TextField
            label="Nombre"
            name="nombre"
            fullWidth
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Apellido Paterno"
            name="apellidoPaterno"
            fullWidth
            value={formData.apellidoPaterno}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Apellido Materno"
            name="apellidoMaterno"
            fullWidth
            value={formData.apellidoMaterno}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Correo"
            name="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Celular"
            name="celular"
            fullWidth
            value={formData.celular}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <TextField
            label="Universidad"
            name="universidad"
            fullWidth
            value={formData.universidad}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ flex: "1 1 100%" }}> {/* Full width */}
          <TextField
            label="Cédula Profesional"
            name="cedulaProfesional"
            fullWidth
            value={formData.cedulaProfesional}
            onChange={handleChange}
          />
        </Box>
      </Box>
  
      {/* Checkbox */}
      <Box sx={{ marginBottom: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.activo}
              onChange={handleCheckboxChange}
            />
          }
          label="Activo"
        />
      </Box>
  
      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
        }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Box>
    </Box>
  
    {/* Snackbar for notifications */}
    <Snackbar
      open={snackbar.open}
      autoHideDuration={4000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  </CardContent>
  
  );
};

export default SustentanteEdit;
