import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Examen } from "../../interfaces/IExam";
import { Box, Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExamCard from "../Cards/ExamCard";
import ExamDetail from "../Dashboard/ExamDetail";
import MonitoreoDetail from "./Monitoreo/MonitoreoDetail";
import { useAuth } from "../../context/AuthContext";
import { getAvailableExams } from "../../services/examServices";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../Loading/LoadingModal";
import { useFetchData } from "../../hooks/services";

const ControlMonitoreo: React.FC = () => {
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const { admin } = useAuth();
  const navigate = useNavigate();

  const { data: examenes, loading, error } = useFetchData(() => getAvailableExams(), []);
  
  if (loading) {
    return  <LoadingModal loading={loading} />;
  }
  return (
    <>
      <>
      {showDetail? <MonitoreoDetail/>:
         <CardContent>
         <Grid container spacing={2} sx={{marginBottom: 2}}>

           <Grid size={10} >
             <Typography
               variant="h6"
               fontWeight="bold"
               component="h2"
               gutterBottom
             >
               Monitoreo de exámenes activos
             </Typography>{" "}
           </Grid>
           <Grid size={2}>
           </Grid>
         </Grid>
         {examenes?.map((examen: Examen) => (
            <ExamCard
              key={"card-" + examen.id}
              examen={examen}
              origen="admin"
              callback={() => {
                navigate(`/control/monitoreo/${examen.id}`, { state: { examName: examen.nombre } });
              }}              isDetail={true}
            />
          )) || []}
       </CardContent>
      }
     
      </>
    </>
  );
};

export default ControlMonitoreo;
