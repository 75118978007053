import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  Link,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import background from "../assets/img/background-login.png";
import logo from "../assets/img/logo.png";
import { loginService } from "../services/authServices";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { loginUser, loginSinodal, user, admin, sinodal } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log(admin, " ADMIN ");
    if (admin) {
      navigate("/control");
    }
    if (user) {
      navigate("/dashboard");
    }
    if (sinodal) {
      navigate("/sinodal");
    }
  }, [user, admin, sinodal]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Por favor, ingrese su correo electrónico y contraseña");
      return;
    }

    setError(null);
    try {
      const response = await loginService(email, password);
      if (response && response.token && response.usuario) {
        if (response.tipoUsuario === "sinodal") {
          loginSinodal(response.token, response.usuario);
          navigate("/sinodal");
        } else {
          loginUser(response.token, response.usuario);
          navigate("/dashboard");
        }
      }
    } catch (err) {
      setError("Usuario y/o contraseña incorrecta.");
      console.error(err);
    }
  };

  return (
    <Box display="flex" height="100vh">
      {/* Left Side - Features List */}
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "#f5f7fa", padding: 4, paddingTop: "10%" }}
      >
        <img
          src={background}
          alt="Background"
          style={{ height: "380px", marginBottom: "180px" }}
        />
      </Box>

      {/* Right Side - Floating Card Login Form */}
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#f5f7fa",
          width: { xs: "100%", sm: "50%", md: "40%" },
        }}
      >
        <Paper
          elevation={1}
          sx={{
            paddingTop: "5%",
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 2,
            width: "100%",
            maxWidth: 350,
            minHeight: "90vh",
            mx: 2,
            textAlign: "center",
          }}
        >
          <img src={logo} width={100} height={100} alt="ExamSys logo" />
          <Typography variant="h5" gutterBottom>
            Iniciar sesión
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? "Hide password" : "Show password"
                    }
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Link href="/forgotpassword" variant="body2" underline="hover">
              ¿Olvidaste tu contraseña?
            </Link>
          </Box> 
         
          <Button
            fullWidth
            variant="contained"
            onClick={handleLogin}
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: "primary",
              color: "white",
              "&:hover": { backgroundColor: "secondary" },
            }}
          >
            Ingresar
          </Button>
          <Typography variant="body2" color="textSecondary">
            ¿Aún no te has registrado?{" "}
            <Link href="/register" variant="body2" underline="hover">
              Registrate
            </Link>
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default Login;
