import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Examen } from "../interfaces/IExam";
import { getAdminExams } from "../services/examServices";
import Examen1 from "../assets/img/exam-1.png";
import LoadingModal from "../components/Loading/LoadingModal";
import { useFetchData } from "../hooks/services";
import ExamCard from "../components/Cards/ExamCard";
import { getShopList } from "../services/ventasService";
import ExamShopCard from "../components/Cards/ExamShopCard";

interface ShopPageProps {
  // cart: Examen[];
  // setCart: React.Dispatch<React.SetStateAction<Examen[]>>;
}

const ShopPage: React.FC<ShopPageProps> = () => {
  const { data: mockExams, loading, error } = useFetchData(() => getShopList(), []);

  const [selectedExam, setSelectedExam] = useState<Examen | null>(null);

  // const handleAddToCart = (exam: Examen) => {
  //   if (!cart.find((item) => item.id === exam.id)) {
  //     setCart([...cart, exam]);
  //   }
  // };

  const inscribieExamenUsuario = () => {
    
  }
  return (
    <>
    <Box padding={2}>
      {/* Header Section */}
      <Box marginBottom={2} textAlign="center">
        <Typography variant="h4" gutterBottom>
            Convocatorias
        </Typography>
        <Typography variant="subtitle1">
          Selecciona una de nuestras convocatorias y registrate para poder acceder a nuestras certificaciones.
        </Typography>
      </Box>

      {/* Cards Section */}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-evenly"
        paddingLeft={20}
        paddingRight={20}
        gap={4}
        alignItems="stretch"
      >
        {mockExams?.map((exam) => (
          <ExamShopCard key={exam.id} examen={exam} callback={ () => {}} />

      //    <Box
      //    key={exam.id}
      //    flex="1 1 calc(33.333% - 32px)" 
      //    maxWidth="calc(33.333% - 32px)" 
      //    minWidth="300px" 
      //    display="flex"
      //    flexDirection="column" 
      //    onClick={() => {
      //      handleOpenDialog(exam);
      //    }}
      //    sx={{
      //      cursor: "pointer",
      //      "&:hover": {
      //        boxShadow: 4, 
      //      },
      //    }}
      //  >
      //    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      //      <CardMedia
      //        component="img"
      //        height="240"
      //        image={Examen1} 
      //        alt={exam.nombre}
      //      />
      //      <CardContent sx={{ flexGrow: 1 }}>
      //        <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
      //          {exam.nombre}
      //        </Typography>
      //        {/* <Typography variant="body2" color="textSecondary" gutterBottom noWrap>
      //          {exam.descripcion}
      //        </Typography> */}
      //      </CardContent>
      //    </Card>
      //  </Box>
        ))}
      </Box>

   

    </Box>
    <LoadingModal loading={loading} />


    </>
  );
};

export default ShopPage;
