import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import {
  getSinodalExams,
} from "../../services/examServices";
import { Examen } from "../../interfaces/IExam";
import LoadingModal from "../Loading/LoadingModal";
import { useFetchData } from "../../hooks/services";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExamCard from "../Cards/ExamCard";

const DashboardSinodalExams: React.FC = () => {
  const navigate = useNavigate();

  const {
    data: examenes,
    loading,
    error,
  } = useFetchData(() => getSinodalExams(), []);

  if (loading) {
    return <LoadingModal loading={loading} />;
  }
  return (
    <CardContent>
      <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
        Exámenes disponibles para sinodal
      </Typography>
      
          
          {examenes && examenes.map((examen) => (
          <ExamCard key={"exam-"+examen.id} examen={examen} isDetail={true} callback={
            () => {  navigate(`/sinodal/sustentantes`, { state: { exam: examen } })
          }
          } />
        ))}

      {/* <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={{backgroundColor: '#2196F3'}}>
            <TableRow>
              <TableCell align="center" sx={{ verticalAlign: "middle",  color: 'white'}}>
                <strong>Nombre</strong>
              </TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle",  color: 'white' }}>
                <strong>Descripción</strong>
              </TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle",  color: 'white' }}>
                <strong>Módulos</strong>
              </TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle",  color: 'white' }}>
                <strong>Preguntas</strong>
              </TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle",  color: 'white' }}>
                <strong>Tiempo limite</strong>
              </TableCell>

              <TableCell colSpan={2} align="center" sx={{ color: 'white'}}>
                <strong>Acciones</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examenes?.map((examen: Examen) => (
              <TableRow key={examen.id}>
                <TableCell sx={{ verticalAlign: "middle" }}>
                  {examen.nombre}
                </TableCell>
                <TableCell sx={{ verticalAlign: "middle" }}>
                  {examen.descripcion}
                </TableCell>
                
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  {examen.totalModulos} módulos
                </TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  {examen.totalPreguntas} preguntas
                </TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  {examen.tiempoLimite} min
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" spacing={1} justifyContent="center">
               
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={
                        () => 
                        navigate(`/sinodal/sustentantes`, { state: { exam: examen } })
                      }
                    >
                      Ver sustentantes
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </CardContent>
  );
};

export default DashboardSinodalExams;
