import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export const ProtectedRouteUser: React.FC = () => {
  const { user, admin , sinodal} = useAuth();

  if (admin || !user || sinodal) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export const ProtectedRouteAdmin: React.FC = () => {
  const { user, admin , sinodal} = useAuth();

  if (user || !admin || sinodal) {
    return <Navigate to="/admin" replace />;
  }

  return <Outlet />;
};

export const ProtectedRouteSinodal: React.FC = () => {
  const { user, admin, sinodal } = useAuth();

  if (admin || !sinodal || user)  {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

