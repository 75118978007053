import axios from "axios";
import { Certificacion, CertificacionCreate, CertificationExam } from "../interfaces/ICertificacion";
import { serviceUrl } from "../Constants/constants";

export const getCertificacionService = async (token: string): Promise<Certificacion[]> => {
    const response = await axios.get(serviceUrl + '/admin/certificacion/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });    return response.data;
  };
  export const getCertificacionByIdService = async (token: string, certId: string): Promise<CertificationExam> => {
    const response = await axios.get(serviceUrl + '/admin/certificacion/'+certId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });    return response.data;
  };
  
  export const updateCertificacion = async (idExamen: string, updatedData: Certificacion ,token: string) => {
    console.log(updatedData,"UPDATE D");
    const url = serviceUrl+'/admin/certificacion/edit/'+idExamen;
    const response = await axios.patch(url, updatedData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`},
    });
    return response.data;
  };

  export const createCertificacion =  async (formData: CertificacionCreate,token: string) => {
    const url = serviceUrl+'/admin/certificacion/nuevo';
    const response = await axios.post(url,formData,{
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })
    return response.data;
};

export const deleteCertificacion = async (idExamen: string,token: string) => {
    const url = serviceUrl+'/admin/certificacion/delete/'+idExamen;
    const response = await axios.delete(url, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}`},
    });
    return response.data;
  };
  export const saveExamToCertificacion = async (formData: any, token: string) => {
    const url = serviceUrl + "/admin/certificacion/examen/agregar";
    try {
      const response = await axios.post(url, formData, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error || "Ocurrio un problema al actuallizar la información";
        throw new Error(errorMessage);
      }
      throw new Error(error.message || "Ocurrio un problema al actuallizar la información");
    }
  };
  
  export const updateExamToCertificacion = async (formData: any, token: string) => {
    const url = serviceUrl + "/admin/certificacion/examen/editar";
    try {
      const response = await axios.patch(url, formData, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error || "Ocurrio un problema al actuallizar la información";
        throw new Error(errorMessage);
      }
      throw new Error(error.message || "Ocurrio un problema al actuallizar la información");
    }
  };
