import React, { useEffect, useState } from "react";
import {
  CardContent,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Paper,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePaper,
  TableRow,
} from "../../../components/Table/CustomeTable";

import { Examen } from "../../../interfaces/IExam";
import { useSnackbar } from "../../Snackbar/Snackbar";
import SnackbarAlert from "../../Snackbar/SnackbarAlert";
import { getAvailableExams } from "../../../services/examServices";
import { useFetchData } from "../../../hooks/services";
import { saveExamToCertificacion, updateExamToCertificacion } from "../../../services/certificacionServices";
import { useAuth } from "../../../context/AuthContext";
import {
  Certificacion,
  CertificationExam,
} from "../../../interfaces/ICertificacion";
import { useNavigate } from "react-router-dom";

interface EditExamenProps {
  certificacion: CertificationExam;
}
const CertificacionesEditExam = ({ certificacion }: EditExamenProps) => {
  const [selectedExam, setSelectedExam] = useState<string | null>(null);
  const [peso, setPeso] = useState<number | string>("");
  const [orden, setOrden] = useState<number | string>("");
  const initExamsCount = certificacion.examenesCert.length;
  const {
    data: examenes,
    loading,
    error,
  } = useFetchData(() => getAvailableExams(), []);

  const [respuestasMinimas, setRespuestasMinimas] = useState<number | string>(
    ""
  );
  const [tableData, setTableData] = useState<
    {
      examenId: string;
      respuestasminimas: number;
      peso: number;
      nombre: string;
      orden: number;
    }[]
  >([]);
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();
  const { adminToken } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (tableData.length === 0) {
      certificacion.examenesCert.forEach((cert) => {
          console.log("CERT ENTER")
        handleAgregar(
          cert.examenId,
          cert.peso,
          cert.respuestasminimas,
          cert.orden,
          cert.examen.nombre
        );
      });
    }
  }, []);

  const handleAgregar = (
    examenId: string | null,
    pe: string | number,
    resp: string | number,
    ord: string | number,
    examenName?: string | null
  ) => {
    if (examenId && pe && resp && ord) {
      if (tableData.some((row) => row.orden === Number(ord))) {
        showSnackbar(
          "El valor de 'Orden' ya existe en la tabla. Por favor, elija otro.",
          "error"
        );
        return;
      }
  
      if (tableData.some((row) => row.examenId === examenId)) {
        showSnackbar(
          "El nombre ya existe en la tabla. Por favor, elija otro.",
          "error"
        );
        return;
      }
  
      if (!examenName) {
        if (!examenes) return;
        const selectedExamData = examenes.find(
          (exam) => exam.id.toString() === examenId
        );
        examenName = selectedExamData?.nombre || "Unknown";
      }
  
      const newRow = {
        examenId: examenId,
        respuestasminimas: Number(resp),
        peso: Number(pe),
        nombre: examenName,
        orden: Number(ord),
      };
  
      console.log("Adding Row: ", newRow);
  
      setTableData((prevTableData) => [...prevTableData, newRow]);
  
      setPeso("");
      setOrden("");
      setRespuestasMinimas("");
      setSelectedExam(null);
    } else {
      showSnackbar(
        "Por favor complete todos los campos antes de agregar.",
        "error"
      );
    }
  };

  const handleGuardar = async () => {
    const formattedData = tableData.map(
      ({ examenId, respuestasminimas, peso, orden }) => ({
        examenId,
        respuestasminimas,
        peso,
        orden
      })
    );
    const sendData = {
      examenes: formattedData,
      certificacionId: certificacion.id,
    };

    try {
      var response;
      if(initExamsCount > 0){
         response = await updateExamToCertificacion( sendData,
          adminToken ?? ""
        );

      }else{
         response = await saveExamToCertificacion( sendData,
          adminToken ?? ""
        );
      }
  
      if (response.error) {
        showSnackbar(response.error, "error");
      } else {
        showSnackbar("Se actualizó la certificación con éxito", "success");
        setTimeout(() => navigate(-1), 1000);

      }
    } catch (error: any) {
      console.error("Error:", error.message);
      showSnackbar(error.message, "error");
    } finally {
    }
  };
  const handleEliminar = (index: number) => {
    setTableData((prevData) => prevData.filter((_, i) => i !== index));
  };
  return (
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Configurar Exámenes
      </Typography>

      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel id="select-examen-label">Seleccionar Examen</InputLabel>
          <Select
            labelId="select-examen-label"
            value={selectedExam || ""}
            onChange={(e) => setSelectedExam(e.target.value)}
          >
            {examenes?.map((examen) => (
              <MenuItem key={examen.id} value={examen.id}>
                {examen.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2} mb={3}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Orden"
            type="number"
            value={orden}
            onChange={(e) => setOrden(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Respuestas Mínimas"
            type="number"
            value={respuestasMinimas}
            onChange={(e) => setRespuestasMinimas(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Peso"
            type="number"
            value={peso}
            onChange={(e) => setPeso(e.target.value)}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleAgregar(selectedExam, peso, respuestasMinimas, orden)
        }
        disabled={!selectedExam || !peso || !respuestasMinimas}
        sx={{ mb: 3 }}
      >
        Agregar
      </Button>

      {tableData.length > 0 && (
        <>
          <TablePaper sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Orden</TableCell>
                  <TableCell>Examen</TableCell>
                  <TableCell>Respuestas Mínimas</TableCell>
                  <TableCell>Peso</TableCell>
                  <TableCell></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.orden}</TableCell>
                    <TableCell>{row.nombre}</TableCell>
                    <TableCell>{row.respuestasminimas}</TableCell>
                    <TableCell>{row.peso}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleEliminar(index)}
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TablePaper>

          <Button variant="contained" color="secondary" onClick={handleGuardar}>
            Guardar
          </Button>
        </>
      )}
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </CardContent>
  );
};

export default CertificacionesEditExam;
