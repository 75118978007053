import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { CertificacionCreate } from "../../../interfaces/ICertificacion";
import { createCertificacion } from "../../../services/certificacionServices";

const CertificacionesCreate: React.FC = () => {
  const { adminToken} = useAuth();
  const [formData, setFormData] = useState<CertificacionCreate>({nombre: "", descripcion: "", estatus: "", activo: false});
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const validateForm = (): boolean => {
    const { nombre, descripcion} = formData;

    if (!nombre || !descripcion) {
      setSnackbar({
        open: true,
        message: "Por favor, complete todos los campos.",
        severity: "error",
      });
      return false;
    }

    return true;
  };
  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    try {
      formData.activo = true;
      formData.estatus = 'activa';
      await createCertificacion(formData, adminToken?? ""); 
      setSnackbar({
        open: true,
        message: "Certificacion actualizado con éxito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error al actualizar el Certificacion:", error);
      setSnackbar({
        open: true,
        message: "Hubo un error al actualizar el Certificacion.",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <CardContent>

    <Typography sx={{marginBottom: 5}} variant="h6" fontWeight="bold" component="h2" gutterBottom>
      Crear Certificacion
    </Typography>
    <Box component="form" onSubmit={handleSubmit} noValidate>
      {/* Fields */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2, 
          marginBottom: 2, 
        }}
      >
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}> {/* Responsive width */}
          <TextField
            label="Nombre"
            name="nombre"
            fullWidth
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </Box>
        <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          
        </Box>
        <TextField
            label="Descripción"
            name="descripcion"
            fullWidth
            value={formData.descripcion}
            onChange={handleChange}
            required
            multiline
            rows={4}
          />
      </Box>
  
      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
        }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Box>
    </Box>
  
    {/* Snackbar for notifications */}
    <Snackbar
      open={snackbar.open}
      autoHideDuration={4000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  </CardContent>
  
  );
};

export default CertificacionesCreate;
