import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Examen } from "../../interfaces/IExam";
import { getFinishedExams } from "../../services/examServices";
import { useAuth } from "../../context/AuthContext";
import Examen1 from "../../assets/img/examCard.png";
import LoadingModal from "../Loading/LoadingModal";
import { getDiploma, getIndividualReport } from "../../services/reportServices";
import { useSnackbar } from "../Snackbar/Snackbar";
import SnackbarAlert from "../Snackbar/SnackbarAlert";

const ResultadosSustentante: React.FC = () => {
  const navigate = useNavigate();
  const [finishedExams, setFinishedExams] = useState<Examen[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth();
  const { snackbar, showSnackbar, handleSnackbarClose } = useSnackbar();

  // const handleStartExam = () => {
  //   navigate("/dashboard/examenes");
  //   console.log("Iniciando examen...");
  // };

  useEffect(() => {
    const fetchFinishedExams = async () => {
      if (!user) return;

      try {
        const userId = user?.id;
        const exams = await getFinishedExams(userId);
        setFinishedExams(exams);
      } catch (error) {
        console.error("Error fetching finished exams:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFinishedExams();
  }, []);

  const handleFileDownload = async (ex: Examen, fileType: "reporte" | "diploma") => {
    try {
      const response = fileType === "reporte" 
        ? await getIndividualReport(ex.examenUsuarioId) 
        : await getDiploma(ex.examenUsuarioId);
  
      const mimeType = fileType === "reporte" ? "application/xlsx" : "application/pdf";
      const fileExtension = fileType === "reporte" ? "xlsx" : "pdf";
  
      const blob = new Blob([response], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${ex.nombre}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      showSnackbar(`Se descargo correctamente el ${fileType}` , "success");

    } catch (error) {
      console.error(`Error downloading student ${fileType}:`, error);
      showSnackbar(`Error al descargar el ${fileType}`, "error");
    }
  };
  
  if (loading) {
    return <LoadingModal loading={loading} />;
  }

  return (
    <>
    <CardContent>
      <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
        Resultados
      </Typography>

      {finishedExams.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {finishedExams.map((exam) => (
            <Card
              key={exam.id}
              sx={{
                maxWidth: 250,
                borderRadius: 2,
              }}
            >
              <img
                src={Examen1}
                alt={exam.nombre}
                style={{ width: "100%", height: "auto", borderRadius: "4px" }}
              />
              <CardContent>
                <Typography fontWeight={"bold"} component="h2" gutterBottom>
                  {exam.nombre}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom >
                  {exam.descripcion}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    mt: 2,
                  }}
                >
                <Typography variant="body2" color="secondary">
                  Pendiente de resultado
                </Typography>
                <Typography variant="body2" color="textSecondary">
                {exam.estatus}
                </Typography>
                  {/* <Button color="primary" variant="contained" onClick={() => handleFileDownload(exam, "reporte")}>
                    Resultados
                  </Button>
                  <Button color="secondary" variant="contained" onClick={() => handleFileDownload(exam, "diploma")}>
                    Constancia
                  </Button> */}
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Alert sx={{ marginTop: 5 }} severity="info">
          Finaliza un examen para poder ver tus resultados en esta sección.
        </Alert>
      )}
    </CardContent>
    <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default ResultadosSustentante;
