import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Shop, ShopCreate, ShopUpdate } from "../../../interfaces/IExam";
import { updateVentas } from "../../../services/ventasService";
import { getAvailableExams } from "../../../services/examServices";
import { useFetchData } from "../../../hooks/services";
import LoadingModal from "../../Loading/LoadingModal";
import InputAdornment from '@mui/material/InputAdornment';

const VentasEdit: React.FC = () => {
  const { state } = useLocation();
  const shopItem: Shop = state?.item;
  const {
    data: examenes,
    loading,
    error,
  } = useFetchData(() => getAvailableExams(), []);
  const [formData, setFormData] = useState<Shop>({ ...shopItem });
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = (): boolean => {
    const { precioVenta, imagenUrl } = formData;

    if ( !precioVenta ||  precioVenta == 0 || !imagenUrl ) {
      setSnackbar({
        open: true,
        message: "Por favor, complete todos los campos.",
        severity: "error",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const itemToUpdate : ShopUpdate = { imagenUrl: formData.imagenUrl, precioVenta: Number(formData.precioVenta), disponible: true}; 
      await updateVentas(formData.id, itemToUpdate);
      setSnackbar({
        open: true,
        message: "Catálogo actualizado con éxito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error al actualizar el catálogo:", error);
      setSnackbar({
        open: true,
        message: "Hubo un error al actualizar el catálogo.",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return <LoadingModal loading={loading} />;
  }
  return (
    <CardContent>
      <Typography
        sx={{ marginBottom: 5 }}
        variant="h6"
        fontWeight="bold"
        component="h2"
        gutterBottom
      >
        Editar información de examen para el catálogo de venta
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        {/* Fields */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2, // Adds consistent spacing
            marginBottom: 2, // Adds space below the inputs
          }}
        >
           <TextField
              label="Nombre del exámen"
              name="examen"
              fullWidth
              value={formData.examen.nombre}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />

          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
            <TextField
              label="URL de imagen para catálogo"
              name="imagenUrl"
              fullWidth
              value={formData.imagenUrl}
              onChange={handleChange}
              required
            />
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Precio de venta</InputLabel>
          <OutlinedInput
          value={formData.precioVenta}
          
          onChange={handleChange}
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Precio de venta"
            name="precioVenta"
          />
        </FormControl>
              {/* <TextField
                    label="Precio de venta"
                    name="precioVenta"
                  fullWidth
                  value={formData.precioVenta}
                  onChange={handleChange}
                  required
                /> */}
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}></Box>
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Box>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </CardContent>
  );
};

export default VentasEdit;
