import React from "react";
import { Outlet, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Badge, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import logo from '../assets/img/logo.png';

interface ShopLayoutProps {
  cart: any[];
}

const ShopLayout: React.FC<ShopLayoutProps> = ({ cart }) => {
  return (
    <Box>
      {/* Navbar */}
      <AppBar position="static" color="primary">
        <Toolbar>
        <Box
            component="img"
            src={logo}
            alt="App Logo"
            sx={{ height: 40, mr: 2 }}
          />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            ExamSys
          </Typography>
          <Box>
            <Link to="/shop" style={{ textDecoration: "none", color: "white", marginRight: "16px" }}>
              Convocatorias
            </Link>
            {/* <Link to="/checkout" style={{ textDecoration: "none", color: "white" }}>
            <IconButton color="inherit" sx={{ ml: 2 }}>
            <Badge badgeContent={cart.length} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
            </Link> */}
          </Box>
          
        </Toolbar>
      </AppBar>

      {/* Render child routes */}
      <Box padding={3}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default ShopLayout;
