import React from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Button, Stack } from "@mui/material";
import { Pregunta, Option } from "../../interfaces/IExam";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

interface QuestionProps {
  question: Pregunta;
  onAnswer: (answer: string) => void;
  onNext: (skip?: boolean) => void;
  onBack?: () => void; 
  onMark: (mark: boolean) => void;
  currentAnswer: string | undefined;
}

const Question: React.FC<QuestionProps> = ({ question, onAnswer, onNext, onBack, onMark, currentAnswer }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        marginBottom: 2,
      }}
    >
            
      <Stack
        direction="row"
        sx={{ mb: 3, justifyContent: "space-between", alignItems: "center" }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          {onBack && (
            <Button variant="outlined" color="primary" onClick={onBack}>
              Atrás
            </Button>
          )}
          <Button variant="outlined" color="secondary" onClick={() => onNext(true)}>
            Saltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onNext()}
            disabled={!currentAnswer}
          >
            Siguiente
          </Button>
        </Box>

        <Box>
          {question.isMarked? 
          <Button
          variant="outlined"
          startIcon={<StarBorderIcon />}
          color="warning"
          onClick={() => onMark(false)}
        >
          Desmarcar pregunta
        </Button>
          :
          <Button
            variant="contained"
            startIcon={<StarIcon />}
            color="warning"
            onClick={() => onMark(true)}
          >
            Marcar pregunta
          </Button>
          }
          
        </Box>
      </Stack>
      <Box sx={{ flexGrow: 1, minHeight: '40vh' }}>
        <Typography variant="h6" fontWeight={"bold"}>{question.texto}</Typography>
        <RadioGroup
          sx={{ mb: 2, mt: 2 }}
          value={currentAnswer || ""}
          onChange={(e) => onAnswer(e.target.value)}
        >
          {question.opciones.map((opcion: Option) => (
            <FormControlLabel
              key={opcion.id}
              value={opcion.texto}
              control={<Radio color="secondary" />}
              label={
                <>
                  {opcion.valor !== null && opcion.valor !== undefined && (
                    <strong style={{ 
                      color: "#ed6c02", 
                      fontSize: "1.2rem"
                  }}>
                      ({Number(opcion.valor).toFixed(1)})
                    </strong>
                  )}
                  {opcion.valor !== null && opcion.valor !== undefined && " - "}
                  {opcion.texto}
                </>
              }
            />
          ))}
        </RadioGroup>
      </Box>

    </Box>
  );
};

export default Question;
