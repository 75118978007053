import React, { useEffect, useState } from "react";
import {
  TablePagination,
  Paper,
  Box,
  Stack,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Checkbox,
  TextField,
} from "@mui/material";
import {
  actionButton,
  EstudianteEstatus,
  EstudianteExamen,
  ExamenResponse,
} from "../../../interfaces/IMonitoreo";
import useFetchData from "../../../hooks/services";
import { updateStatusById, updateZoom } from "../../../services/monitoreoServices";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePaper,
  TableRow,
} from "../../Table/CustomeTable";
import { formatSeconds, formatTimeDifference } from "../../../utils/utils";
import LoadingModal from "../../Loading/LoadingModal";

interface ParticipantsTableProps {
  initialEstudiantes: EstudianteExamen[];
  searchTerm: string;
}
const ParticipantsTable: React.FC<ParticipantsTableProps> = React.memo(
  ({ initialEstudiantes, searchTerm }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [estudiantes, setEstudiantes] =
      useState<EstudianteExamen[]>(initialEstudiantes);
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [selectedAction, setSelectedAction] = useState<actionButton | null>();
    const [selectedId, setSelectedId] = useState<string | null>();
    const [confirmationText, setConfirmationText] = useState<string>("");

    useEffect(() => {
      setEstudiantes(initialEstudiantes);
    }, [initialEstudiantes]);
    const handleOpen = (action: actionButton, id: string) => {
      setSelectedAction(action);
      setSelectedId(id);
      if (action === "cerrar") {
        setOpenConfirm(true);
      } else {
        setOpen(true);
      }
    };

    const handleClose = () => {
      setOpen(false);
      setOpenConfirm(false);
      setSelectedAction(null);
      setSelectedId(null);
      setConfirmationText("");
    };

    const confirmAction = () => {
      if (selectedAction && selectedId) {
        handleButtonClick(selectedAction, selectedId);
      }
      handleClose();
    };
    const filteredEstudiantes = estudiantes?.filter((estudiante) => {
      const nombre = estudiante.estudiante.nombre.toLowerCase();
      const email = estudiante.estudiante.email.toLowerCase();
      const searchLower = searchTerm?.toLowerCase() || "";
      // console.log(searchLower+" "+nombre+" "+email)
      return nombre.includes(searchLower) || email.includes(searchLower);
    });

    const handleButtonClick = async (
      action: actionButton,
      examenUsuarioId: string,
      zoomValue? : boolean
    ) => {
      setLoading(true);
      setError("");

      try {
        if(action === 'zoom'){
            await updateZoom(examenUsuarioId, zoomValue);

            setEstudiantes((prevEstudiantes) => {
              const estudiantesCopy = [...prevEstudiantes];
              const index = estudiantesCopy.findIndex((e) => e.examenUsuarioId === examenUsuarioId);
            
              if (index !== -1) {
                estudiantesCopy[index] = {
                  ...estudiantesCopy[index],
                  estadoZoom: zoomValue?? false,
                };
              }
            
              return estudiantesCopy;
            });

        }else{
          const response = await updateStatusById(examenUsuarioId, action);
          const updatedExam = response.examen;
  
          setEstudiantes((prevEstudiantes) =>
            prevEstudiantes.map((estudiante) =>
              estudiante.examenUsuarioId === updatedExam.id
                ? { ...estudiante, estatus: updatedExam.estatus }
                : estudiante
            )
          );
        }

      } catch (err) {
        setError("Error updating exam status.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const paginatedEstudiantes = filteredEstudiantes.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const getStatusColor = (status: EstudianteEstatus) => {
      switch (status) {
        case "completado":
          return "#4CAF50";
        case "pendiente":
          return "#9E9E9E";
        case "en_progreso":
          return "#2196F3";
        case "pausado":
          return "#FF9800";
        case "detenido":
          return "#F44336";
      }
    };

    return (
      <>
        <TablePaper>
          <Table>
            <TableHead color="#111">
              <TableRow>
                <TableCell colSpan={2} >Nombre del sustentante</TableCell>
                <TableCell align="center">Estatus</TableCell>
                <TableCell align="center">Correo electrónico</TableCell>
                <TableCell align="center">
                  <Typography style={{ whiteSpace: "pre-wrap", fontSize: 14 }}>
                    Preguntas{"\n"}Respondidas
                  </Typography>
                </TableCell>
                <TableCell align="center">
                <Typography style={{ whiteSpace: "pre-wrap", fontSize: 14 }}>
                    Tiempo{"\n"}Restante
                  </Typography>
                </TableCell>
                <TableCell align="center">Estación</TableCell>
                <TableCell align="center">Zoom</TableCell>
                <TableCell colSpan={2} align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedEstudiantes
                .slice()
                .sort((a, b) =>
                  a.estudiante.nombre.localeCompare(b.estudiante.nombre, "es", {
                    sensitivity: "base",
                  })
                )
                .map((estudiante) => (
                <TableRow key={estudiante.examenUsuarioId}>
                  <TableCell colSpan={2}>
                    {estudiante.estudiante.nombre}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          backgroundColor: getStatusColor(estudiante.estatus),
                          display: "inline-block",
                          marginRight: 1,
                        }}
                      />
                      <div>
                        {
                          estudiante.estatus
                            .replace("_", " ") 
                            .toLowerCase() 
                            .replace(/^\w/, (c) => c.toUpperCase()) 
                        }
                      </div>
                    </Box>
                  </TableCell>

                  <TableCell align="center">{estudiante.estudiante.email}</TableCell>
                  <TableCell align="center">{estudiante.preguntasRespondidas}</TableCell>
                  <TableCell align="center">
                    {formatSeconds(estudiante.tiempoRestante)}
                  </TableCell>
                  <TableCell align="center">
                  {estudiante.intentos}
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={estudiante.estadoZoom}
                      onChange={e =>{ handleButtonClick('zoom', estudiante.examenUsuarioId,e.target.checked )}}
                    />
                  </TableCell>
                  <TableCell colSpan={2} >
                    <Stack direction="row" spacing={1} justifyContent="center">
                      {estudiante.estatus === "pausado" ? (
                        <Button
                          onClick={() =>
                            handleOpen("continuar", estudiante.examenUsuarioId)
                          }
                          variant="contained"
                          sx={{ backgroundColor: "#4CAF50", color: "white" }}
                          // disabled={
                          //   estudiante.estatus === "en_progreso" ||
                          //   estudiante.estatus === "pendiente" ||
                          //   estudiante.estatus === "detenido" ||
                          //   estudiante.estatus === "completado"
                          // }
                        >
                          Continuar
                        </Button>
                      ) : (
                        <Button
                          onClick={() =>
                            handleOpen("pausa", estudiante.examenUsuarioId)
                          }
                          variant="contained"
                          sx={{ backgroundColor: "#FF9800", color: "white" }}
                          disabled={
                            // estudiante.estatus === "pausado" ||
                            estudiante.estatus === "pendiente" ||
                            estudiante.estatus === "detenido" ||
                            estudiante.estatus === "completado"
                          }
                        >
                          Pausa
                        </Button>
                      )}

                      <Button
                        onClick={() =>
                          handleOpen("cerrar", estudiante.examenUsuarioId)
                        }
                        variant="contained"
                        sx={{ backgroundColor: "#F44336", color: "white" }}
                        disabled={
                          estudiante.estatus === "detenido" ||
                          estudiante.estatus === "completado"
                        }
                      >
                        Terminar
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredEstudiantes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
          />
        </TablePaper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmar Acción</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas realizar esta acción? Esta acción no
              puede deshacerse.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={confirmAction} color="primary" variant="contained">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openConfirm} onClose={handleClose}>
          <DialogTitle>Confirmar Acción</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Para continuar, escribe la palabra <b>"aceptar"</b> en el cuadro
              de texto a continuación. Esta acción no puede deshacerse.
            </DialogContentText>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="Escribe 'aceptar' para confirmar"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={confirmAction}
              color="primary"
              variant="contained"
              disabled={confirmationText.toLowerCase() !== "aceptar"}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingModal loading={loading} />
      </>
    );
  }
);

export default ParticipantsTable;
