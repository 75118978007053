import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { logoutService, logoutAdminService } from '../services/authServices';
import LoadingModal from '../components/Loading/LoadingModal';
import { Admin, User, Sinodal } from '../interfaces/IUser';

// interface User {
//   id: string;
//   nombre: string;
//   apellidoPaterno: string;
//   email: string;
//   tipoUsuario?: string;
// }


interface AuthContextType {
  user: User | null;
  token: string | null;
  loginUser: (token: string, user: User) => void;
  logoutUser: () => void;

  admin: Admin | null;
  adminToken: string | null;
  loginAdmin: (token: string, admin: Admin) => void;
  logoutAdmin: () => void;

  sinodal: Sinodal | null;
  sinodalToken: string | null;
  loginSinodal: (token: string, user: User) => void;
  logoutSinodal: () => void;

  examMode: boolean;
  examModeSet: (value: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [sinodal, setSinodal] = useState<Sinodal | null>(null);

  const [token, setToken] = useState<string | null>(null);
  const [adminToken, setAdminToken] = useState<string | null>(null);
  const [sinodalToken, setSinodalToken] = useState<string | null>(null);

  const [loading, setLoading] = useState(true);
  const [examMode, setExamMode] = useState<boolean>(false);

  useEffect(() => {
    // Load user and admin from localStorage
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    const storedAdmin = localStorage.getItem('admin');
    const storedAdminToken = localStorage.getItem('adminToken');
    const storedSinodal = localStorage.getItem('sinodal');
    const storedSinodalToken = localStorage.getItem('tokenSinodal');

    if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
    }

    if (storedAdmin && storedAdminToken) {
      setAdmin(JSON.parse(storedAdmin));
      setAdminToken(storedAdminToken);
    }
    if (storedSinodal && storedSinodalToken) {
      setSinodal(JSON.parse(storedSinodal));
      setSinodalToken(storedSinodalToken);
    }
    setLoading(false); 
  }, []);

  const loginUser = (token: string, user: User) => {
    
    setUser(user);
    setToken(token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
  };

  const logoutUser = () => {
    if (user) {
      logoutService(user.id);
    }
    setUser(null);
    setToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  const loginAdmin = (token: string, admin: Admin) => {
    setAdmin(admin);
    setAdminToken(token);
    localStorage.setItem('admin', JSON.stringify(admin));
    localStorage.setItem('adminToken', token);
  };

  const logoutAdmin = () => {
    if (admin) {
      logoutAdminService(admin.id);
    }
    setAdmin(null);
    setAdminToken(null);
    localStorage.removeItem('admin');
    localStorage.removeItem('adminToken');
  };
  const loginSinodal = (token: string, sinodal: Sinodal) => {
    
    setSinodal(sinodal);
    setSinodalToken(token);
    localStorage.setItem('sinodal', JSON.stringify(sinodal));
    localStorage.setItem('tokenSinodal', token);
  };

  const logoutSinodal = () => {
    if (sinodal) {
      logoutService(sinodal.id);
    }
    setSinodal(null);
    setToken(null);
    localStorage.removeItem('sinodal');
    localStorage.removeItem('tokenSinodal');
  };
  const examModeSet = (value: boolean) => {
    setExamMode(value);
  }
  if (loading) return <LoadingModal loading={loading} />;

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        loginUser,
        logoutUser,
        admin,
        adminToken,
        loginAdmin,
        logoutAdmin,
        sinodal,
        sinodalToken,
        loginSinodal,
        logoutSinodal,
        examMode,
        examModeSet
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
