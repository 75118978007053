

import React, { useState } from "react";
import {
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import LoadingModal from "../../Loading/LoadingModal";
import CertificacionesEditExam from "./CertificacionesEditDataExam";
import CertificacionesEditDatos from "./CertificacionesEditData";
import { useLocation } from "react-router-dom";
import { useFetchData } from "../../../hooks/services";
import { getCertificacionByIdService } from "../../../services/certificacionServices";
import { useAuth } from "../../../context/AuthContext";

const CertificacionesEdit = () => {

  const location = useLocation();
  const cert = location.state?.cert;
  const { adminToken} = useAuth();
    
  const { data: certificacion, loading } = useFetchData(() => getCertificacionByIdService(adminToken?? "", cert.id), []);

  const [activeTab, setActiveTab] = useState<number>(0);

  console.log(certificacion,"CERTIFICACION")
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  if(!certificacion){
    return (<></>)
  }
  return (
    <CardContent>
      <Typography
        sx={{ marginBottom: 5 }}
        variant="h6"
        fontWeight="bold"
        component="h2"
        gutterBottom
      >
        Editar Certificación
      </Typography>

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="certification tabs">
          <Tab label="Datos" />
          <Tab label="Exámenes" />
        </Tabs>
      </Box>

      {/* Tab Content */}
      {activeTab === 0 && (
        <Box>
          <CertificacionesEditDatos/>
        </Box>
      )}
      {activeTab === 1 && (
        <Box>
          <CertificacionesEditExam certificacion={certificacion}/>
        </Box>
      )}

      <LoadingModal loading={loading} />
    </CardContent>
  );
};

export default CertificacionesEdit;
