import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ShopCreate } from "../../../interfaces/IExam";
import { createVenta } from "../../../services/ventasService";
import { getAvailableExams } from "../../../services/examServices";
import { useFetchData } from "../../../hooks/services";
import LoadingModal from "../../Loading/LoadingModal";

const VentasCreate: React.FC = () => {
  const [formData, setFormData] = useState<ShopCreate>({
    examenId: "",
    precioVenta: 0,
    imagenUrl: ""
  });
  const {
    data: examenes,
    loading,
    error,
  } = useFetchData(() => getAvailableExams(), []);
  const [selectedExamen, setSelectedExamen] = useState<string>("");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const validateForm = (): boolean => {
    const { precioVenta, imagenUrl } = formData;

    if (!selectedExamen || !precioVenta || precioVenta == 0 || !imagenUrl) {
      setSnackbar({
        open: true,
        message: "Por favor, complete todos los campos.",
        severity: "error",
      });
      return false;
    }

    return true;
  };
  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      formData.examenId = selectedExamen;

      const formDataToSend = {
        ...formData,
        precioVenta: Number(formData.precioVenta), 
        examenId: selectedExamen, 
      };
      await createVenta(formDataToSend);
      setSnackbar({
        open: true,
        message: "Examen actualizado con éxito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error: any) {
      console.error("Error al actualizar el exámen:", error);
      setSnackbar({
        open: true,
        message:
          error?.response.data.error ??
          "Hubo un error al actualizar el exámen.",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  if (loading) {
    return <LoadingModal loading={loading} />;
  }
  return (
    <CardContent>
      <Typography
        sx={{ marginBottom: 5 }}
        variant="h6"
        fontWeight="bold"
        component="h2"
        gutterBottom
      >
        Crear Examen para el catálogo de venta
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        {/* Fields */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            marginBottom: 2,
          }}
        >
          <FormControl fullWidth variant="outlined">
            <InputLabel id="examen-select-label">
              Exámenes disponibles
            </InputLabel>
            <Select
              labelId="examen-select-label"
              value={selectedExamen}
              onChange={(e) => setSelectedExamen(e.target.value)}
              label="Exámenes disponibles"
            >
              {examenes &&
                examenes.map((examen) => (
                  <MenuItem key={examen.id} value={examen.id}>
                    {examen.nombre}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            label="URL de imagen para catálogo"
            name="imagenUrl"
            fullWidth
            value={formData.imagenUrl}
            onChange={handleChange}
            required
          />
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Precio de venta</InputLabel>
          <OutlinedInput
          value={formData.precioVenta}
          onChange={handleChange}
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Precio de venta"
            name="precioVenta"
          />
          </FormControl>
          </Box>
          <Box sx={{ flex: "1 1 calc(50% - 16px)" }}></Box>
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Regresar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Box>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </CardContent>
  );
};

export default VentasCreate;
