import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  Snackbar,
  Alert,
  AlertColor,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { AdminModulo, Modulo } from "../../../interfaces/IExam";
import {
  getAdminQuestions,
  updateModule,
} from "../../../services/examServices";
import QuestionsForm from "./QuestionsForm";
import Module from "module";
import { useNavigate } from "react-router-dom";

interface ModuleFormProps {
  modulos: AdminModulo[];
  onSave: (updatedModules: AdminModulo[]) => void;
}

const ModuleForm: React.FC<ModuleFormProps> = ({ modulos, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initModule, setInitModule] = useState<AdminModulo>();
  const [selectedModule, setSelectedModule] = useState<AdminModulo>();
  const [modulesLoaded, setModulesLoaded] = useState<AdminModulo[]>(modulos);
  const [status, setStatus] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    severity: AlertColor;
    message: string;
  }>({
    open: false,
    severity: "info",
    message: "",
  });

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (selectedModule) {
  //     const fetchQuestions = async () => {
  //       try {
  //         const data = await getAdminQuestions(selectedModule.examenId);
  //         setQuestions(data);
  //         modulesLoaded.map((module) =>
  //           module.id.toString() === selectedModule?.id
  //             ? { ...selectedModule }
  //             : module
  //         );
  //       } catch (error) {
  //         console.error("Error fetching questions:", error);
  //         setSnackbar({
  //           open: true,
  //           message: "Error al cargar las preguntas.",
  //           severity: "error",
  //         });
  //       }
  //     };
  //     fetchQuestions();
  //   }
  // }, [selectedModule]);

  useEffect(() => {
    setModulesLoaded(modulos);
  }, [modulos]);
  useEffect(() => {
    if (modulesLoaded.length > 0) {
      setSelectedModule(modulesLoaded[0]);
      setStatus(modulesLoaded[0].activo);
    }
  }, [modulesLoaded]);

  const handleSave = async () => {
    try {
      if (selectedModule) {
        await updateModule(selectedModule?.id, selectedModule).then((res) => {
          onSave(res);
          const updatedModulos = modulos.map((module) =>
            module.id.toString() === selectedModule?.id
              ? { ...selectedModule }
              : module
          );
          setModulesLoaded(updatedModulos);
        });
        setSnackbar({
          open: true,
          message: "Examen actualizado con éxito.",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error updating exam:", error);
      setSnackbar({
        open: true,
        message: "Error al actualizar el examen.",
        severity: "error",
      });
    }
    // onSave(updatedModules);
    // setSnackbar({
    //   open: true,
    //   message: "Módulo actualizado con éxito.",
    //   severity: "success",
    // });
    setIsEditing(false);
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleModuleChange = (event: SelectChangeEvent<string>) => {
    const selected = modulesLoaded.find(
      (mod) => mod.id.toString() === event.target.value
    );
    if (selected) {
      setStatus(selected.activo);
      setSelectedModule(selected);
    }
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
    if (selectedModule) {
      setSelectedModule({ ...selectedModule, activo: event.target.checked });
    }
  };
  const editQuestions = () => {
    navigate(`/control/preguntas/${selectedModule?.id}`, { state: { selectedModule } })
  }
  return (
    <Box width="100%" margin="auto" marginTop={2}>
      <Card
        sx={{
          boxShadow: 3,
          padding: 2,
          marginBottom: 3,
          backgroundColor: "#f9f9f9",
        }}
      >
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={3}
          >
            <Typography
               sx={{ fontWeight: "bold", color: "#1e293b", fontSize: 18 }}

            >
              Modificar módulo
            </Typography>
            <Box>
              {isEditing && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                  sx={{ marginRight: 1 }}
                >
                  Cancelar
                </Button>
              )}
              <Button
                variant="contained"
                color={isEditing ? "primary" : "secondary"}
                onClick={isEditing ? handleSave : handleEditToggle}
              >
                {isEditing ? "Guardar" : "Editar"}
              </Button>
            </Box>
          </Box>

          <Box display="grid" gap={2} gridTemplateColumns="1fr 3fr">
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", alignSelf: "center" }}
            >
              Seleccionar módulo:
            </Typography>
            {isEditing ? (
              <TextField
                name="nombre"
                value={selectedModule?.nombre}
                onChange={(e) => {
                  if (selectedModule) {
                    setSelectedModule({
                      ...selectedModule,
                      nombre: e.target.value,
                    });
                  }
                }}
                size="small"
                fullWidth
                variant="outlined"
                sx={{ backgroundColor: "white" }}
              />
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
  <FormControl fullWidth variant="outlined" sx={{ flex: 1 }}>
    <InputLabel id="module-select-label">Módulos</InputLabel>
    <Select
      labelId="module-select-label"
      value={selectedModule?.id.toString() || ""}
      onChange={handleModuleChange}
      label="Módulos"
      size="small"
    >
      {modulesLoaded.map((mod) => (
        <MenuItem key={mod.id} value={mod.id.toString()}>
          {mod.nombre}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  
  <Button 
    variant="contained" 
    color="primary" 
    sx={{ height: 'auto', paddingY: 1, paddingX: 2, fontSize: '0.875rem' }}
    onClick={editQuestions}
  >
    Actualizar preguntas
  </Button>
</Box>
            )}

            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", alignSelf: "center" }}
            >
              Estado:
            </Typography>
            {isEditing ? (
              <FormControlLabel
                control={
                  <Checkbox checked={status} onChange={handleStatusChange} />
                }
                label="Activo"
              />
            ) : (
              <Typography>{status ? "Activo" : "Inactivo"}</Typography>
            )}
          </Box>
        </CardContent>
      </Card>

      {/* <Card sx={{ boxShadow: 3, padding: 2, marginBottom: 3, backgroundColor: "#f9f9f9" }}>
        <CardContent>
          <QuestionsForm questions={questions} />
        </CardContent>
      </Card> */}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ModuleForm;
