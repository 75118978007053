import React, { useState, useEffect, useCallback } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { Pregunta, Option, AdminModulo } from "../../../interfaces/IExam";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdminQuestions } from "../../../services/examServices";
import { useFetchData } from "../../../hooks/services";
import QuestionAccordion from "./QuestionAccordion";
import LoadingModal from "../../Loading/LoadingModal";
import { useFetchDataReload } from "../../../hooks/fetchReload";

interface QuestionsEditProps {}

const QuestionsEdit: React.FC<QuestionsEditProps> = () => {
  const location = useLocation();
  const modulo: AdminModulo = location.state?.selectedModule;
  const [questions, setQuestions] = useState<Pregunta[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchStatus = async () => {
      try {
        setLoading(true);
        const response = await getAdminQuestions(modulo.id + "");
        if (!response) {
          throw new Error("Failed to fetch data");
        }
        setQuestions(response);
      } catch (error) {
        console.error("Error fetching status:", error);
      } finally {
        setLoading(false);
      }
    };
    useEffect( () => {
      fetchStatus();
    },[])

    const reload = () => {
      console.log("RELOAD")
      fetchStatus();
    }
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <LoadingModal loading={loading} />;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
          {"Preguntas del modulo " + modulo.nombre}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginLeft: "auto" }}
          onClick={goBack}
        >
          Regresar
        </Button>
      </Box>
      {questions &&
        questions.map((q, index) => (
          <QuestionAccordion
            key={index}
            callback={reload} // Pass the reload function to the accordion
            question={q}
          />
        ))}
    </Box>
  );
};

export default QuestionsEdit;
