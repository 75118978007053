import axios from "axios";
import { actionButton, ExamenMonitoreo, ExamenResponse } from "../interfaces/IMonitoreo";
import { serviceUrl } from "../Constants/constants";

export const getExamStudents = async (id: string): Promise<ExamenMonitoreo> => {
  const response = await axios.get(`${serviceUrl}/api/monitor/examen/${id}`);
  return response.data;
};

  export const updateStatusById = async (id: string, action : actionButton): Promise<ExamenResponse> => {
    var url = "";
    if(action === 'continuar'){
      url = serviceUrl+'/api/monitor/examen/reanudar/';
    }else if(action === 'cerrar'){
      url = serviceUrl+'/api/monitor/examen/detener/';
    }else if(action === 'pausa'){
        url = serviceUrl+'/api/monitor/examen/pausar/';
    }
    const response = await axios.patch(url+id);
    return response.data;
  };

  export const updateZoom = async (examenUsuarioId: string, estatus?: boolean) => {
    const estadoZoom = estatus ? "true" : "false";

    const url = `${serviceUrl}/api/monitor/examen/zoom/${examenUsuarioId}/${estadoZoom}`;
    console.log("Request URL:", url);
    const response = await axios.patch(url);
    return response.data;
  };