import axios from 'axios';
import { LoginResponse, IRegister, LoginAdminResponse, ChangePassword } from '../interfaces/IUser';
import { serviceUrl } from '../Constants/constants';

export const loginService = async (email: string, password: string)  => {
    try {
        const urlLog = serviceUrl+'/v2/auth/login';
        //const response = await axios.post<LoginResponse>(serviceUrl+'/auth/login', {
        const response = await axios.post<LoginResponse>(urlLog, {        
            email,
            password,
        });
       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};
export const loginAdminService = async (email: string, password: string)  => {
    try {
        const response = await axios.post<LoginAdminResponse>(serviceUrl+'/admin/auth/login', {
            email,
            password,
        });

       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};
export const registerService = async (body: IRegister)  => {
    try {
    //    const response = await axios.post<LoginResponse>(serviceUrl+'/api/users/crear', body);
       const response = await axios.post<LoginResponse>(serviceUrl+'/api/users/crear/inscribir', body); 

       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};

export const logoutService = async (uid: string)  => {
    try {
       const response = await axios.post<LoginResponse>(serviceUrl+'/auth/logout', {"usuarioId": uid});
       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};

export const logoutAdminService = async (uid: string)  => {
    try {
       const response = await axios.post<LoginResponse>(serviceUrl+'/auth/logout', {"usuarioId": uid});
       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};


export const requestChangePassword = async (email: string)  => {
    try {
       const response = await axios.post<ChangePassword>(serviceUrl+'/auth/password/solicitar', {"email": email});
       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};

export const requestRecoverPassword = async (token: string, password: string)  => {
    try {
       const response = await axios.post<ChangePassword>(serviceUrl+'/auth/password/restablecer', {"token": token, "nuevaContrasena": password});
       return response.data;

    } catch (error: any) {
        throw new Error(error);
    }
};