import React from "react";
import { Box, Typography, Card, CardContent, Divider, Stack, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ThankYouPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        bgcolor: "#f5f5f5",
        padding: 2,
      }}
    >
      <CheckCircleIcon sx={{ color: "green", fontSize: 80, marginBottom: 2 }} />
      <Typography variant="h4" gutterBottom>
        ¡Gracias por tu compra!
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Tu compra se ha completado con éxito.
      </Typography>

      <Card sx={{ maxWidth: 400, marginTop: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Resumen de tu compra
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Stack spacing={1}>
            
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" sx={{paddingRight: 1}}>Producto:</Typography>
              <Typography variant="body2" fontWeight="bold">
                Competencias Clínicas para Médicos Generales
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Cantidad:</Typography>
              <Typography variant="body2" fontWeight="bold">
                1
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Precio:</Typography>
              <Typography variant="body2" fontWeight="bold">
                $5,000.00
              </Typography>
            </Box>
            <Divider sx={{ marginY: 1 }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" fontWeight="bold">
                Total:
              </Typography>
              <Typography variant="body1" fontWeight="bold" color="primary">
                $5,000.00
              </Typography>
            </Box>
            <Button variant="contained" sx={{margin: 2}}>Salir</Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ThankYouPage;
