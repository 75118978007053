import React, { useState } from "react";
import { uploadExam } from "../../../services/examServices";
import { Alert, Box, Button, Snackbar, Typography, Link } from "@mui/material";
import LoadingModal from "../../Loading/LoadingModal";
import { useAuth } from "../../../context/AuthContext";
import FileUpload from "react-material-file-upload";

const ExamenUpload = () => {
  const { adminToken } = useAuth();
  interface FormData {
    file: any;
  }
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file before uploading.");
      return;
    }

    var formData: FormData;
    formData = { file: selectedFile[0] };
    console.log(formData);
    setLoading(true);

    try {
      const exams = await uploadExam(formData, adminToken ?? "");
      console.log(exams, "EXAMS");
      setSnackbar({
        open: true,
        message: "El exámen se subió correctamente.",
        severity: "success",
      });
    } catch (error: any) {
      console.error("Error al subir el exámen:", error);
      setSnackbar({
        open: true,
        message:
          error?.response.data.error ??
          "Hubo un error al actualizar el exámen.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box width="100%" margin="auto">
        <Box sx={{ width: "80%" }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            component="h2"
            gutterBottom
            sx={{ mt: 1, mb: 1 }}
          >
            Subir Examen
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ mt: 2, mb: 2, color: "black" }}>
          Descargue y llene el siguiente archivo de Excel con la información
          necesaria para subir un examen:{" "}
          <Link
            href="/files/Examen-Ejemplo.xlsx"
            download="Examen-Ejemplo.xlsx"
            underline="hover"
          >
            Descargar plantilla de Excel
          </Link>
        </Typography>
        <FileUpload
          accept={".xlsx"}
          buttonText="Seleccionar"
          title="Arrastra el archivo o selecciona tu achivo"
          maxFiles={1}
          value={selectedFile}
          onChange={setSelectedFile}
        />

        <Button
          sx={{ marginTop: 3 }}
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!selectedFile}
        >
          Subir examen
        </Button>
      </Box>
      <LoadingModal loading={loading}></LoadingModal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ExamenUpload;
