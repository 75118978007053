import { useState, useEffect, useRef } from "react";

export const useFetchData = <T>(fetchFunction: () => Promise<T>, dependencies: any[] = []) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log("LOADING")
      setError(null);
        await fetchFunction().then( result => {
          setData(result);

        }).catch(err => {
          setError(err as Error);

        }).finally( () => {
          console.log("CLOSE LOADING")
          setLoading(false);
        })
     
    };

    fetchData();
  }, dependencies);

  return { data, loading, error };
};


export default useFetchData;
