import React from "react";
import { Box, Typography, CardContent } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import BadgeIcon from "@mui/icons-material/Badge";
import EmailIcon from "@mui/icons-material/Email";
import { EstudiantePractico } from "../../interfaces/IExam";

interface SustentanteInfoBoxProps {
  sustentante: EstudiantePractico;
}

const SustentanteInfoBox: React.FC<SustentanteInfoBoxProps> = ({ sustentante }) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: 2,
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#f5f7fa",
        paddingTop: 2,
        paddingLeft: 2,
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        height: "auto",
        width: "100%",
      }}
    >
      <CardContent sx={{ width: "100%", padding: 0 }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PersonIcon color="primary" />
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" color="textSecondary">
                Nombre del Sustentante
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {`${sustentante.nombre} ${sustentante.apellidoPaterno} ${sustentante.apellidoMaterno}`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <BadgeIcon color="primary" />
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" color="textSecondary">
                Cédula Profesional
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {sustentante.cedulaProfesional ?? "No disponible"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <EmailIcon color="primary" />
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" color="textSecondary">
                Email
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {sustentante.email}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Box>
  );
};

export default SustentanteInfoBox;
