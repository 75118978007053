import React, { useState } from "react";
import {
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { Certificacion } from "../../../interfaces/ICertificacion";
import { deleteCertificacion, updateCertificacion } from "../../../services/certificacionServices";
import LoadingModal from "../../Loading/LoadingModal";


const CertificacionesEditDatos: React.FC = () => {
  const { adminToken} = useAuth();
  const { state } = useLocation();
  const cert: Certificacion = state?.cert;
  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<Certificacion>({
    ...cert,
    activo: cert.activo ?? false,
  });
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFormData((prev) => ({ ...prev, activo: checked }));
  };
  const validateForm = (): boolean => {
    const { nombre, descripcion } = formData;

    if (!nombre || !descripcion ) {
      setSnackbar({
        open: true,
        message: "Por favor, complete todos los campos.",
        severity: "error",
      });
      setLoading(false);
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (!validateForm()) {
      return
    };
    const newData: Certificacion = {
      id: formData.id,
      nombre: formData.nombre,
      descripcion: formData.descripcion,
      estatus: 'activa',
      activo: formData.activo,
      fechaInicio: formData.fechaInicio,
      examenes: formData.examenes ?? "0"
    }
    try {
      await updateCertificacion(formData.id, newData, adminToken?? ""); 
      setSnackbar({
        open: true,
        message: "Certificación actualizado con éxito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error al actualizar el Certificación:", error);
      setSnackbar({
        open: true,
        message: "Hubo un error al actualizar el Certificación.",
        severity: "error",
      });
    }
    setLoading(false);

  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (!cert) {
    return <Typography>No se encontraron Certificaciones activos.</Typography>;
  }
  const deleteItem = async () => {
    try {
      await deleteCertificacion(formData.id, adminToken?? ""); 
      setSnackbar({
        open: true,
        message: "Certificación eliminado con exito.",
        severity: "success",
      });
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error al actualizar el Certificación:", error);
      setSnackbar({
        open: true,
        message: "Hubo un error al eliminar el Certificación.",
        severity: "error",
      });
    }
  }

  return (
    <CardContent>

    <Box component="form" onSubmit={handleSubmit} noValidate>
      {/* Fields */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2, 
          marginBottom: 2, 
        }}
      >
         <Box sx={{ flex: "1 1 calc(50% - 16px)" }}> {/* Responsive width */}
           <TextField
             label="Nombre"
             name="nombre"
             fullWidth
             value={formData.nombre}
             onChange={handleChange}
             required
           />
         </Box>
         <Box sx={{ flex: "1 1 calc(50% - 16px)" }}>
           
         </Box>

         <TextField
             label="Descripción"
             name="descripcion"
             fullWidth
             value={formData.descripcion}
             onChange={handleChange}
             required
             multiline
             rows={4}
           />
      </Box>
  
      {/* Checkbox */}
      <Box sx={{ marginBottom: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.activo}
              onChange={handleCheckboxChange}
            />
          }
          label="Activo"
        />
      </Box>
  
      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
        }}
      >
        <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Guardar
        </Button>
        <Button variant="contained" color="error" onClick={deleteItem}>
          Eliminar
        </Button>
      </Box>
    </Box>
  
    {/* Snackbar for notifications */}
    <Snackbar
      open={snackbar.open}
      autoHideDuration={4000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
    <LoadingModal loading={loading}/>
  </CardContent>
  
  );
};

export default CertificacionesEditDatos;
