import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  Typography,
  Box,
  Chip,
  Stack,
  IconButton,
} from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import Examen1 from "../../assets/img/exam-1.png";
import DefaultImage from "../../assets/img/defaultImage.png";
import { Shop } from "../../interfaces/IExam";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { es } from "date-fns/locale";
interface ExamenCardProps {
  examen: Shop;
  callback: (examenId: string) => void;
  isDetail?: boolean;
  origen?: string;
}
const ExamShopCard = ({ examen, callback, isDetail, origen }: ExamenCardProps) => {
  const [openPay, setOpenPay] = useState(false);
  const [notAuthDialog, setNotAuthDialog] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [expired, setExpired] = useState(false);

  const validateExpiredExam = (applicationDate: string): boolean => {
    const currentDate = new Date();
    const examDate = new Date(applicationDate); 
    return currentDate < examDate; 
  };

  useEffect( () => {
    const isExpired = validateExpiredExam(examen.examen.fechaAplicacion);
    setExpired(isExpired);
  },[])

  const handleOpenDialog = () => {
    setOpenPay(true);
  };

  const handleCloseDialog = () => {
    setOpenPay(false);
  };
  const handlePayment = async () => {
    if (!user) {
      setNotAuthDialog(true);
      return;
    }
    
    try {
      window.open(examen.urlPago, '_blank'); // Abre la URL en una nueva pestaña

    } catch (error) {
      console.error("Payment redirection failed:", error);
    }
  };
  
  return (
    <>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderRadius: 2,
          boxShadow: 1,
          marginBottom: 2
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 100,
            height: 100,
            borderRadius: 1,
          }}
          image={examen?.imagenUrl || DefaultImage} 
          alt={examen?.examen.nombre || "Default Image"}
        />

        {/* Content Section */}
        <CardContent sx={{ flex: "1", paddingLeft: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            {examen.examen.nombre}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: 0.5 }}
          >
            {examen.examen.descripcion}
          </Typography>

          {/* Tags */}
          {!isDetail && (
            <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mt={1}
              >
                <IconButton size="small" color="secondary" sx={{ padding: 0 }}>
                  <CalendarMonthIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" color="textSecondary" ml={1}>
                  {  format(new Date(examen.examen.fechaAplicacion), "dd'/'MMM'/'yyyy '-' hh:mm a", { locale: es })
                  } 
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mt={1}
                pl={2}
              >
                <IconButton size="small" color="secondary" sx={{ padding: 0 }}>
                  <MonetizationOnIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" color="textSecondary" ml={1}>
                  {new Intl.NumberFormat('es-MX', { 
                    style: 'currency', 
                    currency: 'MXN', 
                    minimumFractionDigits: 0, 
                    maximumFractionDigits: 0 
                  }).format(examen.precioVenta)} MXN
                </Typography>
              </Box>
            </Stack>
          )}
        </CardContent>

        <Box sx={{ textAlign: "right" }}>
          {!isDetail && (
            <>
              <Typography variant="body2" color="textSecondary">
                Duración
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {examen.examen.tiempoLimite} minutos
              </Typography>
            </>
          )}
          {isDetail ? (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {!expired  || origen == 'admin'? 
              <IconButton color="primary" onClick={() => callback(examen.id)}>
              <ArrowForwardIosIcon />
            </IconButton>:
              <Typography sx={{color: "red"}}>{"No disponible"}</Typography> 
              
              }

            </Box>
          ) : (
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={handleOpenDialog}
              color="secondary"
            >
              {"Inscribir"}
            </Button>
          )}
        </Box>
      </Card>
      {/* Alert Modal */}
      {/* <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        ¿Quieres proceder con la inscripción para el examen?
      </DialogTitle>
      <DialogContent sx={{ padding: 3 }}>
        <Typography variant="body1" color="textSecondary">
          Al confirmar tu inscripción, quedará registrado tu acceso al examen.
          Podrás realizarlo cuando estés listo.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={() => callback(examen.id)}
          variant="outlined"
          color="primary"
          sx={{ ml: 1 }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog> */}

       {/* Dialog Section */}
       <Dialog open={openPay} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
  <DialogTitle>
    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center'}}>
      {examen?.examen.nombre}
    </Typography>
  </DialogTitle>
  <DialogContent>
    <Box display="flex" alignItems="flex-start" gap={2}>
      {/* Image Section */}
      <Box flexShrink={0}>
      <CardMedia
        component="img"
        sx={{
          width: 150,
          height: 150,
          borderRadius: 2,
        }}
        image={examen?.imagenUrl || DefaultImage} 
        alt={examen?.examen.nombre || "Default Image"}
      />
      </Box>
      {/* Content Section */}
      <Box flex="1">
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {examen?.examen.descripcion}
        </Typography>
        <Box
                display="flex"
                alignItems="center"
                mt={1}
              >
                <IconButton size="small" color="secondary" sx={{ padding: 0 }}>
                  <CalendarMonthIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" color="textSecondary" ml={1}>
                  {  format(new Date(examen.examen.fechaAplicacion), "dd'/'MMM'/'yyyy '-' hh:mm a", { locale: es })
                  } 
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                mt={1}
              >
                <IconButton size="small" color="secondary" sx={{ padding: 0 }}>
                  <MonetizationOnIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2" color="primary" fontSize={18} fontWeight={'bold'} ml={1}>
                  {new Intl.NumberFormat('es-MX', { 
                    style: 'currency', 
                    currency: 'MXN', 
                    minimumFractionDigits: 0, 
                    maximumFractionDigits: 0 
                  }).format(examen.precioVenta)} MXN
                </Typography>
              </Box>
      </Box>
    </Box>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="secondary">
      Cerrar
    </Button>
    <Button
        onClick={handlePayment}

      color="primary"
      variant="contained"
    >
      Pagar
    </Button>
  </DialogActions>
</Dialog>

          {/* Dialog for User Action */}
          <Dialog open={notAuthDialog} onClose={() => setNotAuthDialog(false)}>
            <DialogTitle>Inicia sesión o regístrate</DialogTitle>
            <DialogContent>
              <Typography>
                Para completar la compra, necesitas iniciar sesión o registrarte.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => navigate("/")} color="primary">
                Iniciar sesión
              </Button>
              <Button onClick={() => navigate("/register")} color="secondary">
                Registrarme
              </Button>
            </DialogActions>
          </Dialog>
    </>
  );
};

export default ExamShopCard;
