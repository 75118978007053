export const formatTimeDifference = (date: string): string => {
  const targetDate = new Date(date);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = targetDate.getTime() - currentDate.getTime();

  // If the difference is negative, return 0
  if (timeDifference <= 0) {
    return "00:00:00";
  }

  // Convert milliseconds to seconds, minutes, and hours
  const totalSeconds = Math.floor(timeDifference / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format as 00:00:00 (HH:MM:SS)
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  return formattedTime;
};

export function formatSeconds(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const pad = (num: number) => num.toString().padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}