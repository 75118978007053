import React, { useState } from "react";
import {
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Box,  
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid2";
import { getAdminExams } from "../../services/examServices";
import { Examen } from "../../interfaces/IExam";
import { getExamCompleteReport, getExamPsicometricoReport, getIndividualReport } from "../../services/reportServices";
import { getExamStudents } from "../../services/monitoreoServices";
import { EstudianteExamen } from "../../interfaces/IMonitoreo";
import ReportStudentDialog from "../Dialog/ReportStudentDialog";
import LoadingModal from "../Loading/LoadingModal";
import { useFetchData } from "../../hooks/services";

import { Table, TableBody, TableCell, TableHead, TablePaper, TableRow } from "../Table/CustomeTable";


const ControlReporte: React.FC = () => {
  const { data: examenes, loading, error } = useFetchData(() => getAdminExams(), []);
  const [students, setStudents] = useState<EstudianteExamen[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<EstudianteExamen | null>();

  const tiposExamen: Record<string, string> = {
    teorico: "Teórico",
    practico: "Práctico",
  };

  if (loading) {
    return (
      <LoadingModal loading={loading} />
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        Ocurrió un error al cargar los exámenes.
      </Typography>
    );
  }

  const handleDownload = async (examId: string, isPsicometrico?: boolean) => {
    try {
      var response;
      let reportetipo;
      if(isPsicometrico)
      {
         response = await getExamPsicometricoReport(examId);
         reportetipo = 'psicometrico';
      }
      else
      {
        response = await getExamCompleteReport(examId);
        reportetipo = 'condensado'
      }

      const blob = new Blob([response], { type: "application/xlsx" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `reporte_${reportetipo}_exam_${examId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const handleIndividual = async (id: string) => {
    try {
      const examStudents = await getExamStudents(id);
      setStudents(examStudents.estudiantes);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };



  const handleCloseModal = () => {
    setOpenModal(false);
    setStudents([]);
    setSelectedStudent(null);
  };

  return (
    <CardContent>
      <Grid container sx={{ marginBottom: 2 }} alignItems="center">
        <Box sx={{ width: "80%" }}>
          <Typography variant="h6" fontWeight="bold" component="h2" gutterBottom>
            Reporte de exámenes
          </Typography>
        </Box>
      </Grid>

      <TablePaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Nombre del examen</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Tipo</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Fecha de aplicación</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Reporte Psicométrico </strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Reporte Condensado</strong></TableCell>
              <TableCell align="center" sx={{ verticalAlign: "middle" }}><strong>Reporte Individual</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examenes?.map((examen: Examen) => (
              <TableRow key={examen.id}>
                <TableCell sx={{ verticalAlign: "middle" }}>{examen.nombre}</TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>{tiposExamen[examen.tipoExamen] || examen.tipoExamen}</TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  {examen.fechaAplicacion
                    ? examen.fechaAplicacion.split("T")[0].split("-").reverse().join("/")
                    : "Fecha no disponible"}

                </TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(examen.id.toString(), true)}
                  >
                    Descargar
                  </Button>
                </TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(examen.id.toString())}
                  >
                    Descargar
                  </Button>
                </TableCell>
                <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleIndividual(examen.id.toString())}
                  >
                    Individual
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TablePaper>

      {/* Modal */}

      <ReportStudentDialog       openModal={openModal}
  handleCloseModal ={handleCloseModal} 
  students={students}/>
   
    </CardContent>
  );
};

export default ControlReporte;
